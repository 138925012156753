$font-path: "../weber";
@import "fonts";
@import "ui-kit-mixins";
@import "variables";
@import "./styles/app";
.navbar-fixed-top + .main {
  @media (max-width: $screen-md) {
    padding-top: 58px;
  }
}

p {
  font-family: Calibri, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #666666;
}

h1 {
  font-family: Weber;
  font-size: 45px;
}

.slideshow-inner-grid {
  width: 100%;
  max-width: 892px;
  margin-left: auto;

}

.main_container {
  background-color: #FFED00;
  padding-left: 19px;
  box-sizing: border-box;
}

.banner {
  width: 100%;
  background-color: white;

  .slideshow-wide .slideshow-caption {
    height: 100%;
    padding: 0;
    @media (max-width: $screen-xs) {
      margin-top: auto;
      margin-bottom: auto;
      width: 100%;
    }

    .slideshow-info {
      width: auto;
      height: 100%;
      margin: 0px;
    }

    @media (max-width: $screen-xs) {
      padding-bottom: 75%;

    }
  }

  .container {

    @media (max-width: $screen-md) {
      padding-left: 50px;
    }
    @media (max-width: $screen-xs) {
      padding-left: 0px;
    }
  }
}


.banner-info_2 {
  h2 {
    color: white;
  }

  h4 {
    color: #FFED00;
  }
}

.banner-info {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 0;
  padding-bottom: 80px;
  justify-content: center;

  @media (max-width: $screen-sm) {
    flex-wrap: wrap;
  }
  @media (max-width: $screen-xs) {
    margin-left: 30px;
  }

  img {
    margin: 0px;
    @media (max-width: $screen-sm) {
      width: 170px;
    }
    @media (max-width: $screen-xs) {
      width: 150px;
    }
  }

  &_1 {
    h4 {
      color: #AF3233;
    }
  }

  &_3 {
    h4 {
      color: #AF3233;
    }
  }

  h2 {
    font-family: Weber;
    font-size: 34px;
    line-height: 30px;
    margin: 0;
    @media (max-width: $screen-md) {
      font-size: 28px;
    }
    @media (max-width: $screen-xs) {
      font-size: 24px;
    }
  }

  h4 {
    font-weight: normal;
    font-family: Weber;
    font-size: 50px;
    line-height: 44px;
    margin-top: 18px;
    margin-bottom: 18px;
    @media (max-width: $screen-md) {
      font-size: 40px;
      line-height: 40px;
    }
    @media (max-width: $screen-sm) {
      font-size: 34px;
      line-height: 34px;
      margin-top: 8px;
      margin-bottom: 8px;
    }
    @media (max-width: $screen-xs) {
      font-size: 24px;
      line-height: 20px;
    }
  }
}

.slideshow-inner:not(.slick-initialized) {
  .banner-info {
    justify-content: unset;
    padding-top: 48px;
  }
}

.banner-description {
  span {
    font-weight: 500;
  }
;

  p {
    font-size: 22px;
    line-height: 100%;
    margin-top: 20px;
    color: black;
    padding-top: 7px;
    font-family: Weber;
    @media (max-width: $screen-md) {
      font-size: 16px;
      line-height: 100%;
    }

  }

  @media (max-width: $screen-md) {
    padding-right: 25%;
    min-height: 83px;
  }
  @media (max-width: $screen-sm) {
    flex: auto;
    padding-bottom: 5px;
  }
  @media (max-width: $screen-xs) {
    padding-right: 0px;
    padding-left: 30px;
    flex: auto;
    padding-bottom: 5px;
  }

  .navbar-brand {
    justify-content: start !important;
  }

  background-color: #FFED00;
  min-height: 107px;
  margin-top: -7px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.banner-features {
  margin-right: 20px;
  font-family: Weber;

  .container {
    display: flex;
    flex-direction: row;

    .banner-packs {
      position: absolute;
      align-self: end;
      width: 372px;
      align-self: flex-end;
      margin-left: 625px;
      margin-bottom: -5px;
      @media (max-width: $screen-md) {
        width: 342px;
        margin-left: 395px;
      }
      @media (max-width: $screen-sm) {
        width: 300px;
        margin-left: 300px;
      }
      @media (max-width: $screen-xs) {
        position: unset;
        width: 300px;
        margin-left: 0px;
        left: auto;
        align-self: center;

      }
    }

    @media (max-width: $screen-xs) {

      flex-direction: column;

    }
  }

  .banner-features-usp {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 25%;
    padding-right: 182px;
    display: flex;
    flex-direction: row;
    @media (max-width: $screen-md) {
      flex-wrap: wrap;
      padding-right: 171px;
    }
    @media (max-width: $screen-sm) {
      flex-direction: column;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-right: 150px;
    }
    @media (max-width: $screen-xs) {
      flex-direction: column;

      padding-right: 0px;
      margin-right: 0px;

    }
  }

  .banner-features-item {
    display: flex;
    flex: 1;
    flex-basis: 150px;
    flex-direction: row;
    align-items: center;

    img {
      height: 48px;
      margin-top: 0;
      margin-right: 5px;
      @media (max-width: $screen-sm) {
        height: 33px;
      }
    }

    p {
      font-family: Weber;

      margin: 0;
      font-size: 14px;
      line-height: 14px;
      color: #313131
    }

    @media (max-width: $screen-sm) {
      flex: auto;
      padding-bottom: 5px;
    }
  }
}

.banner-inner {
  padding-left: 20px;
  max-width: 1301px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $screen-sm) {
    @media (max-height: 750px) {
      transform: scale(0.9);
      margin-bottom: -35px;
    }
    @media (max-height: 680px) {
      transform: scale(0.8);
      margin-bottom: -60px;
      margin-top: -30px;
    }
  }
}

.banner-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 3px;

  picture {
    width: 100%;
  }

  @media (max-width: $screen-xs) {
    margin-right: 0px;
    margin-bottom: 3px;
  }
}

.banner-right {
  picture {
    width: 100%;
  }
}

.banner-images {
  display: flex;
  flex-direction: row;
  min-height: 300px;
  @media (max-width: $screen-md) {
    min-height: 200px;
  }
  @media (max-width: $screen-sm) {
    min-height: 130px;

  }
  @media (max-width: $screen-xs) {
    flex-direction: column;
    min-height: 250px;

  }

}


/*блок "попробуйте все продукты"*/
.container {
  width: 1000px;
  @media (max-width: $screen-md) {
    width: auto;
    padding-left: 21px;
  }
}

.entry-title {
  padding-right: 60px;
  @include heading(64px, 0, #FFEB00);
  padding-left: 14px;
  font-size: 56px;
  margin-bottom: 56px;
  @media (max-width: $screen-md) {
    font-size: 40px;
    margin-bottom: 12px;
    &:before {
      top: -4px;
      width: 54px;
      height: 54px;
    }
  }
  @media (max-width: $screen-md) {
    font-size: 40px;
    margin-bottom: 12px;
    padding-left: 7px;
    &:before {
      top: -4px;
      width: 54px;
      height: 54px;
    }
  }
  @media (max-width: $screen-sm) {
    font-size: 34px;
    margin-bottom: 12px;
    padding-left: 7px;
    &:before {
      top: -2px;
      width: 42px;
      height: 42px;
    }
  }
  @media (max-width: $screen-xs) {
    font-size: 30px;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-top: 0;
    &:before {
      width: 37px;
      height: 37px;
    }


    .videos-cover & {
        margin-bottom: 24px;
    }

  }
}

.try {
  background: #FFED00;
  padding-bottom: 72px;
  padding-top: 42px;
  @media (max-width: $screen-sm) {
    padding-top: 42px;

  }
  @media (max-width: $screen-xs) {
    padding-top: 30px;
  }

  h2 {
    max-width: unset;

    &:before {
      background: #FFFFFF;
    }


    > .icon {
      color: #fff;
    }
  }

  &_blocks {
    display: flex;
    flex-direction: row;
  }

  &_inner {
    display: flex;
    flex-direction: row;
  }

  &_about {
    display: flex;
    flex-direction: column;
    background: white;
    //width: 284px;
    height: 100%;
    min-height: 460px;
    border-radius: 12px;

    margin-top: 36px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    align-items: flex-start;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: $screen-sm) {
      max-width: 280px;
      min-height: unset;
    }

    @media (max-width: $screen-xs) {
      max-width: 280px;
      padding-left: 13px;
      padding-right: 18px;
      padding-bottom: 23px;
    }

  }

  &_img {
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -33px;
  }

  &_header {
    font-size: 20px;
    font-family: Weber;
    letter-spacing: -1px;
    font-weight: 400;
    margin-bottom: 0;
  }

  &_p {
    color: #949494;
    font-size: 18px;
    margin-top: 6px;
    flex: 1;
  }
}


.try_button {
  background-color: #FFED00;
  border-radius: 20px;
  border: none;
  padding: 6px 14px;
  font-family: Weber;
  cursor: pointer;
}

.ellipse {
  display: flex;
  min-height: 70px;
  width: 70px;
  margin-top: -48px;
  margin-left: 130px;
  border-radius: 50%;
  font-size: 12px;
  font-family: Weber;
  justify-content: center;
  align-items: center;

  &_gray {
    background: #696969;
    color: white;
  }

  &_white {
    background: #F0F0F0;
    color: black;
  }
}

/*блок "преимущества"*/
.advantage {
  padding-top: 50px;
  background-color: white;
  padding-bottom: 80px;


  > .icon {
    color: #fff;
  }


  &-img {
    width: 178px;
    border-radius: 12px;
    margin: auto;
  }

  &_p {
    font-size: 14px;
    margin-top: 7px;
  }

}


@media (max-width: 1024px) {


  .try_img {
    height: 238px;
    margin-top: -30px;
  }

  .h2-try {
    margin-top: 232px;
    margin-left: 19px;
    font-size: 20px;
    letter-spacing: 1px;
  }

  .try_p {
    font-size: 18px;
    line-height: 17px;
    letter-spacing: -0.8px;
  }

  .try_button {
    padding: 7px 17px;
    letter-spacing: 0.5px;
    font-size: 15px;
  }

  .try {
    &_p {
      font-size: 15px;
      margin-top: 10px;
      letter-spacing: 0.5px;
    }
  }

  .advantage {
    padding-top: 48px;
    padding-bottom: 44px;

    .entry-title {
      margin-bottom: 32px;
    }
  }

  .h1-advantage {
    letter-spacing: -0.8px;
  }

  .advantage-img {
    width: 210px;
  }

  .h3-advantage {
    margin-top: 7px;
    margin-bottom: 12px;
    letter-spacing: -0.4px;
    font-size: 22px;
  }

}

@media (max-width: 768px) {

  .advantage {
    padding-top: 32px;
    padding-bottom: 20px;

    .entry-title {
      margin-bottom: 20px;
    }
    &-img {
      width: 100%;
      height: 210px;
      object-fit: cover;
    }
  }

  .try {
    padding-bottom: 0;

    &-img {
      width: 162px;
      height: 157px;
    }

    &_p {
      line-height: 20px;
    }
  }

  .h1-try {
    margin-left: -115px;
    position: absolute;
    height: 300px;
    margin-top: -6.7px;
    line-height: 36px;
    letter-spacing: 0.6px;
    width: 60%;
    font-size: 32px;
  }

  .try_img {
    height: 187px;
  }

  .ellipse {
    height: 75px;
    width: 75px;
    margin-left: 115px;
  }

  .h2-try {
    letter-spacing: 0.7px;
    margin-top: 178px;
    margin-left: 16px;
  }

  .try-p {
    margin-top: 210px;
    margin-left: 16px;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.5px;
    width: 25%;
  }

  .try_button {
    padding: 5px 14px;
  }

  .ellipse4 {
    margin-left: -5px;
    margin-top: 40px;
    height: 45px;
    width: 45px;
  }

  .h1-advantage {
    letter-spacing: -0.1px;
    margin-top: -1px;
    margin-left: 5px;
    font-size: 33px;
  }
}

@media (max-width: 320px) {
  .header {
    height: 905px;
  }

  .ellipse {
    height: 37px;
    width: 37px;
  }

  .h1-try {
    line-height: 30px;
    letter-spacing: -1.5px;
    width: 17%;
    margin-top: -25px;
    margin-left: -113px;
  }

  .try_img {
    height: 38%;
    margin-top: -42px;
  }

  .ellipse {
    height: 81px;
    width: 81px;
    margin-left: 162px;
  }

  .h2-try {
    letter-spacing: 0.8px;
    margin-top: 230px;
    margin-left: 27px;
  }

  .try-p {
    margin-top: 265px;
    margin-left: 28px;
    letter-spacing: 0.4px;
    width: 17%;
    letter-spacing: 0.5px;
  }

  .try_button {
    font-weight: bold;
  }

  .h1-advantage {
    letter-spacing: -0.5px;
    margin-top: -3px;
    font-size: 30px;
  }

  .advantage-img {
    width: 281px;
  }

  .h3-advantage {
    margin-top: 7px;
  }

  .advantage_p {
    letter-spacing: 0.7px;
  }
}


.landing-stores-cover-section {
  background-color: #FFEB00;
}

.landing-stores-cover {
  padding-top: 60px;
  padding-bottom: 60px;
  @media (max-width: 690px) {
    padding-top: 61px;

  }
  @media (max-width: $screen-xs) {
    padding-top: 28px;
  }

  h2 {

    &:before {
      background: #FFFFFF;
    }

  }

  .control-label {
    color: #807700;
    margin-bottom: 12px;
  }

  .btn-light-ext {
    margin-top: 36px;
  }
}


.videos-cover {
  padding-top: 50px;
  padding-bottom: 60px;
  background-color: white;
  @media (max-width: $screen-sm) {
    padding-top: 40px;
    padding-bottom: 10px;
  }

  .label-primary {
    font-family: Weber;
    font-weight: normal;
    text-transform: unset !important;
    padding: 4px 8px;
    margin-top: 6px;
  }

  .entry-datetime-small {
    color: #9E9E9E;
  }

  .thumbnail-title {
    font-family: Weber;
  }

  .thumbnail-image img {
    object-fit: cover;
  }
}


.slideshow-banner {

  .slideshow-inner:not(.slick-initialized) {
    display: flex;

    flex-direction: column;
    align-items: center;

    .slideshow-item:not(:first-child) {
      display: none;
    }
  }

  img {

  }

  button {
    font-family: Weber;
    padding: 4px 20px;
  }

  @media (min-width: 481px) {
    .slick-dots {
      left: auto;
      top: 50%;
      margin-top: -50px;
      margin-left: -20px;
      width: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;

      text-align: left;
      max-width: 1440px;
      position: absolute;

      li {
        width: auto;
        margin-left: 5px;
      }
    }
  }

  .slick-dots {
    @media (max-width: $screen-xs) {


      margin: 0px;
      margin-right: 20px;
    }

  }

  @media (min-width: 1500px) {
    .slick-dots {
      //left: calc((100vw - 1440px) / 2);
    }
  }
}

.slideshow-comfort {
  .slideshow-inner:not(.slick-initialized) {
    display: flex;
    flex-direction: column;
    align-items: center;

    .slideshow-item:not(:first-child) {
      display: none;
    }
  }

  img {
    margin-left: auto;
    margin-right: auto;
  }

  button {
    font-family: Weber;
    padding: 4px 20px;
  }

  .slick-dots {
    margin-top: -62px;

    & li > button {
      background-color: transparent;
    }

    & .slick-active {
      > button {
        opacity: 1;
        transform: scale(1.2);
        background-color: transparent;
      }
    }
  }

  .slider-progress {
    background-color: #BFBFBF;

    .slider-progress-inner {
      background-color: $brand-primary;
      height: 10px;
    }
  }

  .slideshow-item {
    height: 1250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .cover-slideshow-image {
    height: 326px;
    object-fit: contain;
  }

  @media (min-width: $screen-sm-min) {
    .slideshow-item {
      height: 1024px;

      .container {
        height: 100%;
      }
    }
    .cover-slideshow-image {
      height: 550px;
    }
  }
  @media (min-width: $screen-md-min) {
    .slideshow-item {
      height: 100%;
    }
    .cover-slideshow-image {
      height: 100%;
      max-height: 722px;
    }
  }
  @media (min-width: $screen-lg-min) {

  }
}

.menu {
  flex: 1;
  justify-content: flex-end;
}

.navbar-nav {
  @media (max-width: $screen-sm) {
    flex-direction: column;
  }
}

.navbar-phone-number {
  font-family: Weber;
  font-weight: unset !important;
}

.navbar #nav-main {
  box-shadow: unset !important;
  @media (max-width: $screen-sm) {
    background: $brand-primary;
    padding-top: 20px;
    padding-bottom: 20px;
    a {
      text-align: center;
      font-size: 24px;

      &.navbar-phone-number {
        font-size: 20px;
      }
    }
    .navbar-nav + .navbar-nav {
      border-top: unset;
      margin-top: 24px;
    }
  }
}


.slideshow-transition {
  width: 282px;
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: flex-start;

  .slideshow-icon {
    margin-top: 12px;
    transition: transform linear 200ms;
    transform: scale(0);
  }
}


.slideshow-text {
  margin-left: 12px;
  overflow: hidden;

  p {
    margin-top: 16px;
    font-size: 18px;
  }

  h3, p + h3 {
    margin-top: 0;
  }

  * {
    position: relative;
    left: -100%;
    transition: left 200ms ease-out;
  }
}

.slideshow-text-center {

  margin-top: auto;
  margin-bottom: auto;

  p {
    padding-top: 10px;
    margin-bottom: 0;
  }
}

.slideshow-transition.active {
  * {
    left: 0;
  }

  .slideshow-icon {
    transform: scale(1);
  }
}

.slideshow-column {
  position: absolute;
}

.slideshow-comfort-info {
  h3 {
    font-family: Weber, Helvetica, Arial, sans-serif;
    font-weight: 400;
  }
}

.slideshow-comfort-info:nth-of-type(1) {
  margin-top: 30px;
  @media (min-width: $screen-md-min) {
    margin-top: 80px;
  }
}

.slideshow-comfort-info:nth-of-type(2) {
  margin-top: 106px;
  @media (min-width: $screen-md-min) {
    margin-top: 166px;
  }
}

.slideshow-comfort-info:nth-of-type(3) {
  margin-top: 220px;
  @media (min-width: $screen-md-min) {
    margin-top: 270px;
  }
}

.slideshow-comfort-info:nth-of-type(4) {
  margin-top: 420px;
  margin-left: 0;
  @media (min-width: $screen-sm-min) {
    margin-top: 220px;
    margin-left: $container-sm - 282;
  }
  @media (min-width: $screen-md-min) {
    margin-top: 270px;
    margin-left: $container-md - 282;
  }
  @media (min-width: $screen-lg-min) {
    margin-left: $container-lg - 282;
  }
}

.slideshow-comfort-info:nth-of-type(5) {
  margin-top: 620px;
  @media (min-width: $screen-sm-min) {
    margin-top: 420px;
  }
  @media (min-width: $screen-md-min) {
    margin-top: 470px;
  }
}

.slideshow-comfort-info:nth-of-type(6) {
  margin-top: 820px;
  margin-left: 0;
  @media (min-width: $screen-sm-min) {
    margin-top: 420px;
    margin-left: $container-sm - 282;
  }
  @media (min-width: $screen-md-min) {
    margin-top: 470px;
    margin-left: $container-md - 282;
  }
  @media (min-width: $screen-lg-min) {
    margin-left: $container-lg - 282;
  }
  @media (min-width: $screen-md-min) {

  }
}


@keyframes float {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.8);
  }
}

.slideshow-comfort-button {
  z-index: 1;
  width: 88vw;
  height: 27vw;
  max-height: 147px;
  object-fit: contain;
  object-position: right;
  transform: scale(0.8);
  animation: float 3s ease-in-out infinite;
  cursor: pointer;
  position: relative;
  margin-top: 100px;
  @media (min-width: $screen-sm-min) {
    width: 200px;
    position: relative;
    float: right;
    margin-right: -200px;
    margin-top: 160px;
  }
  @media (min-width: $screen-md-min) {
    margin-top: -400px;
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.slideshow-rotate-button {
  display: flex;
  position: relative;
  margin: -96px calc(50vw - 32px) 32px;
  cursor: pointer;
  &:hover {
    animation: rotate 3s linear infinite;
  }

  * {
    transition: fill 0.3s;
  }
  &.a {
    * {
      fill: white;
    }
    circle {
      fill: #333333;
    }
  }

  &.b {
    * {
      fill: black;
    }
    circle {
      fill: #FFED00;
    }
  }
}

.btn-filled {
  &.btn:focus {
    color: white;
  }
}

#consumption-calculator {
  font-size: 22px
}

#consumption-calculator-container {
  font-size: 18px;
}

#calcModal .modal-dialog{
  margin: 30vh auto;
  @media (min-width: $screen-sm-min) {
    margin: 40vh auto;
  }
}

#calc-bags-value {
  display: inline-block;
  font-weight: bold;
  margin-left: 6px;
  font-size: 22px;
}

.results_consumption {
  h4 {
    font-size: 22px;
    @media (min-width: $screen-sm-min) {
      margin-top: 0;
    }
  }
}
#edit-container-type-calculator {
  margin-top: 8px;
  @media (min-width: $screen-sm-min) {
    margin-top: 10px;
  }
}

.partner-logo {
  max-width: 200px;
  height: 90px;
  display: flex;
  margin: auto;
  img {
    object-fit: contain;
    margin: 0 auto;
    height: 90px;
  }
}

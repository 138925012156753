.bg-cover {
    background-size: cover;
}

.search-cover {
    padding-top: 30px;
    padding-bottom: 40px;
    @media (max-width: $screen-sm-max) {
        padding-bottom: 15px;
    }
    @media (max-width: $screen-xs-max) {
        padding-top: 5px;
        padding-bottom: 0;
    }
    .form-control {
        height: 40px;
        border-radius: 20px;
        margin-right: 16px;
        border-color: transparent;
        box-shadow: none;
        font-size: $font-size-large;
        width: 100%;
    }
    .navbar-form {
        padding: 10px 0 15px 15px;

        .cover-form-group,
        .cover-input-group {
            width: 100%;
        }

        .cover-input-group {
            display: flex;
        }
    }
}
.search-cover-light {
    padding-top: 50px;
    @media (max-width: $screen-xs-max) {
        padding-top: 22px;
    }
    .form-control {
        height: 40px;
        border-radius: 20px;
        margin-right: 16px;
        border: unset;
        box-shadow: none;
        padding-left: 22px;
        font-size: $font-size-large;
        width: 100%;
        max-width: 512px;
        @media (max-width: $screen-xs-max) {
            margin-bottom: 10px;
        }
    }
    .cover-form-group {
        padding-top: 60px;
        @media (max-width: $screen-sm-max) {
            padding-bottom: 15px;
        }
        @media (max-width: $screen-xs-max) {
            padding-top: 36px;
            padding-bottom: 0;
        }
    }
    .navbar-form {
        padding: 10px 0 15px 15px;

        .cover-form-group,
        .cover-input-group {
            width: 100%;
        }

        .cover-input-group {
            display: flex;
        }
    }
}

.menu-cover {
    position: relative;
    z-index: 10;
    height: 140px;
    margin-top: -70px;
    margin-bottom: -70px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #FFFFFF;
    border-radius: 12px;


    @media (max-width: 690px) {
        height: 234px;
        margin-top: -80px;
        margin-bottom: -154px;
    }

    @media (max-width: $screen-xs) {
        height: 312px;
        margin-bottom: -242px;
        margin-left: 15px;
        margin-right: 15px;
        padding: 10px;
    }
}

.cover-slideshow-image {
    height: 100%;
    object-fit: cover;
    @media (max-width: $screen-sm) {
        object-position: right;
    }
    @media (max-width: 480px) {
        object-fit: cover;
        width: 100%;
        object-position: bottom;
    }
}

.products-cover-section {
    background-color: #FFEB00;
}

.products-cover {
    padding-top: 70px;
    @media (max-width: 690px) {
        padding-top: 154px;

    }
    @media (max-width: $screen-xs) {
        padding-top: 242px;
    }

}

@mixin cover-paragraph {
    margin-left: 80px;
    margin-right: 20px;
    font-size: 18px;
    @media (max-width: $screen-sm-max) {
        margin-left: 0;
    }
}

.store-locator-section {
    background-size: cover;
}

.store-locator-cover {
    padding-top: 64px;
    padding-bottom: 78px;

    @media (max-width: $screen-sm-max) {
        padding-top: 42px;
        padding-bottom: 56px;
    }

    @media (max-width: $screen-xs-max) {
        padding-top: 28px;
        padding-bottom: 32px;
    }

    &-row {
        margin-top: 36px;
        display: flex;
        flex-direction: row;
        @media (max-width: $screen-xs-max) {
            margin-top: 18px;
        }
    }

    h2 {
        @include heading(68px, 5px, #FFEB00);
        font-size: 56px;
        @media (max-width: $screen-sm-max) {
            font-size: 32px;
            &:before {
                width: 44px;
                height: 44px;
            }
        }
    }
    p {
        @include cover-paragraph;
        max-width: 210px;
    }
    a {
        @include links(12px, 6px, #FFEB00);
        font-size: 26px;
        line-height: 32px;
        min-width: 110px;
        height: fit-content;
    }
}

.blog-page-cover {
    @extend .store-locator-cover;
    background-size: cover;
    padding-left: 76px;
    @media (max-width: $screen-sm-max) {
        padding-left: 12px;
    }
    margin-bottom: 24px;
}

.articles-cover-description {
    background-color: #FFEB00;
    border-radius: 8px;
    padding-top: 43px;
    padding-bottom: 55px;
    margin-top: 60px;
    margin-bottom: 20px;

    @media (max-width: $screen-xs-max) {
        padding-top: 16px;
        padding-bottom: 16px;
        margin-top: 30px;
    }
    @media (min-width: $screen-md-min) {
        margin-left: 30px;
        padding-left: 65px;
    }

    h2 {
        @include heading(92px, -25px, #FFFFFF);
        font-size: 80px;
        @media (max-width: $screen-sm-max) {
            font-size: 46px;
            &:before {
                width: 54px;
                height: 54px;
            }
        }
    }
    p {
        @include cover-paragraph;
    }
}

.articles-container {
    @media (min-width: $screen-md-min) {
        height: 768px;
    }
}

.articles-cover {
    @media (min-width: $screen-md-min) {
        margin-left: -80px;
    }

    .thumbnail {
        background-color: transparent;
        .label {
            padding: 4px .6em 2px;
        }
        .thumbnail-image {
            overflow: unset;
        }
    }

    .thumbnail:nth-child(3) {
        padding-top: 56.25%;
    }

    section {
        padding-top: 288px;
    }
}

.articles-cover-sideimage {
    height: 360px;
    @media (min-width: $screen-md-min) {
        height: 768px;
        margin-bottom: -768px;
        background-repeat: no-repeat;
        background-position-x: right;
    }
    @media (max-width: $screen-sm-max) {
        display: none;
    }
}

.articles-cover-sideimage-mobile {
    display: none;
    height: 360px;
    margin-bottom: -8px;
    background-size: cover;
    background-repeat: no-repeat;
    @media (max-width: $screen-sm-max) {
        display: block;
        background-position-x: center;
    }
}

.thumbnail-sideimage {
    position: relative;
    top: 165px;
    @media (min-width: $screen-md-min) {
        left: 10%;
    }
    @media (min-width: $screen-lg-min) {
        left: 15%;
    }
    @media (max-width: $screen-sm-max) {
        //top: unset;
        margin-bottom: 20px;
        //margin-bottom: 160px;
    }
}


.search-icon-animation {

}

body[data-embedded-search=true] .search-icon-animation {
    pointer-events: none;
    background-color: #FFEB00;
}

.store-map {
    height: 500px;
    @media (min-width: 1280px) {
        .strip {
            margin-left: 30px;
        }
    }
    @media (min-width: 992px) {
        .strip {
            margin-left: 20px;
        }
    }
    @media (min-width: 768px) {
        .strip {
            margin-left: 10px;
        }
    }
}

.small-checkbox {
    @include checkbox(#FFEB00, #EEEEEE, #646464, #323232);

    & label {
        &::before {
            margin: 3px 11px 1px 1px;
            min-width: 18px;
            width: 18px;
            max-width: 18px;
            height: 18px;
        }
    }
}

.small-checkbox-white {
    @include checkbox(#FFFFFF, #FFFFFF, #646464, #323232);

    & label {
        &::before {
            margin: 3px 11px 1px 1px;
            min-width: 18px;
            width: 18px;
            max-width: 18px;
            height: 18px;
        }
    }
}

.map-filters {
    display: flex;
    align-items: center;
    > .small-checkbox-white {
        margin-left: 32px;
        margin-right: 32px;
    }
    @media (max-width: 999px) {
        > .small-checkbox-white {
            margin-left: 16px;
            margin-right: 0;
        }
    }
    @media (max-width: $screen-xs-max) {
        flex-direction: column;
        align-items: start;
        > .small-checkbox-white {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.map {
    min-width: 600px;
    height: 500px;

    &__balloon {
        position: absolute;
        display: block;
        background: #FFFFFF;
        border-radius: 10px;
        box-shadow: 0 2px 14px rgba(0, 0, 0, 0.02);
        z-index: 1010;
        padding: 20px;

        &__close {
            width: 16px;
            height: 15px;
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;

            background-image: url(../images/balloon-exit.png);
            opacity: 1;
        }

        &__arrow {
            bottom: -20px;
            left: 50%;
            margin-left: -20px;

            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;

            border-top: 20px solid #FFFFFF;

            position: absolute;
            display: block;
        }

        &__shop_name {
            font-weight: bold;
            font-size: 20px;
            line-height: 32px;
            color: #323232;
        }

        &__shop_address {
            margin-top: 8px;
            font-size: 14px;
            line-height: 18px;
            color: #646464;
        }

        &__shop_phone {
            margin-top: 8px;
            font-size: 14px;
            line-height: 18px;
            color: #646464;
        }

        &__shop_url {
            display: inline-block;
            margin-top: 16px;
            font-size: 14px;
            line-height: 17px;
            color: #323232;
            @include links(12px, 6px, #FFEB00)
        }

        &__cluster_text {
            font-size: 20px;
            line-height: 24px;
            display: inherit;
        }

        &__pin {
            display: flex;
            flex-direction: column;
            position: relative;
            align-items: center;
            width: 24px;
            height: 32px;
            margin-top: -32px;
            margin-left: -12px;
            align-items: center;

            &__shop_icon {
                position: absolute;
                bottom: 32px;
                max-width: 100px;
                max-height: 100px;
                padding: 8px;
            }

            &__icon {
                width: 100%;
                height: 100%;
            }
        }
    }
}


.articles-list-category {

}

.jumbotron {
    .ui-tabs .ui-tabs-nav li {
        margin-right: 20px;
        &::after {
            margin: 4px 0 0;
        }
    }
}
.show-more-link {
    @include links(12px, 6px, #FFEB00);
    font-size: 20px;
    height: fit-content;
    cursor: pointer;
}

.see-also-col {
    display: flex;
    flex-direction: column;
    > div {
        width: 100%!important; // fixme: get rid of bootstrap
        padding-left: 0;
        padding-right: 0;
    }
}
.entry .see-also-col{
    > h3 {
        font-weight: bold;
        margin-bottom: 12px;
    }
}

.store-locator-city-picker {
    max-width: 282px!important;
    border-radius: 2px!important;
    margin-bottom: 24px;

    @media (min-width: $screen-md-min) {
        float: right;
        margin-top: -90px;
        position: relative;
        z-index: 1;
        margin-bottom: 0;
    }
}

.store-locator-modal-city-picker {
    max-width: 282px!important;
    border-radius: 2px!important;
    margin-bottom: 24px;

    @media (min-width: $screen-md-min) {
        margin-left: 24px;
        position: relative;
        z-index: 1;
        margin-bottom: 0;
    }
}

.store-locator-modal-form {
    display: flex;
    flex: 1;
    flex-direction: column;

    @media (min-width: $screen-md-min) {
        flex-direction: row;
    }
}

#shop-search-form.search-cover-light {
    padding-top: 0;
}

.article-styles {
    h3 {
        font-weight: bold!important; // fixme it is set in entry
    }
    p {
        color: #646464;
    }
    ul {
        @include list;
        > li {
            margin-top: 8px;
        }
    }
}

.block-focus {
    background: $brand-primary;
    border-radius: 8px;
    padding: 30px 20px;
    &.entry blockquote {
        padding: 0;
        &:before {
            background-color: #FFFFFF;
        }
        @media (min-width: $screen-md-min) {
            padding: 12px 64px;
        }
    }
    .entry-title, .entry-title-sm {
        margin-bottom: 20px;
        @media (min-width: $screen-md-min) {
            margin-bottom: 42px;
        }
        &:before {
            background: #FFFFFF;
        }
    }
    @media (min-width: $screen-sm-min) {
        padding: 40px;
    }
    @media (min-width: $screen-md-min) {
        padding: 60px;
    }
}


#fsModal1, #fsModal2, #fsModal3 {
  background-color: white;
  border-left: 20px solid #ffeb00;
  padding-left: 0!important;

  .modal-dialog {
    margin: 30px 0 0 0;
  }
  .modal-content {
    margin: 15px 0 0 0;
  }
}

#calcModal {
  .modal-content {
    padding: 8px;
    background-color: #FFEB00;
    @media (min-width: 768px) {
      padding: 16px;
      max-width: 75vw;
      margin-left: 12.5vw;
    }
  }
  .ui-dialog .ui-dialog-titlebar-close {
    top: 16px;
    span {
      position: relative;
    }
  }
}

div[data-toggle="modal"] {
  cursor: pointer;
}

.close-row {
  display: flex;
  margin-bottom: -80px;
  height: 60px;
  align-items: center;
}

.close-button {
  margin-left: auto;
  z-index: 1;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    content: "";
    position: absolute;

    background: #ffeb00;

    width: 20px;
    height: 20px;
    border-radius: 50%;

    transition: opacity 300ms;
    z-index: -1;
    opacity: 0;
  }
  &:hover:before {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  transition: opacity 400ms;
}

.fadeIn.in {
  opacity: 1;
}

// =====================================================================================================================
// Component: Icons
// =====================================================================================================================


$icon-size:     16px !default;

.icon {
    width: $icon-size;
    height: $icon-size;
    vertical-align: middle;
    display: inline-block;
}

.icon-2x {
    width: ($icon-size * 2);
    height: ($icon-size * 2);
}

.icon-3x {
    width: ($icon-size * 3);
    height: ($icon-size * 3);
}

.icon-4x {
    width: ($icon-size * 4);
    height: ($icon-size * 4);
}

.icon-social {
    width: 20px;
    height: 20px;
}

// =====================================================================================================================
// Component: Footer
// =====================================================================================================================

$footer-narrower-xs: 22px;
$footer-narrower-sm: 56px;
$footer-narrower-md: 68px;
$footer-narrower-lg: 72px;
@mixin footer-narrow {
    padding-left: $footer-narrower-xs;
    padding-right: $footer-narrower-xs;
    @media (min-width: $screen-sm-min) {
        padding-left: $footer-narrower-sm;
        padding-right: $footer-narrower-sm;
    }
    @media (min-width: $screen-md-min) {
        padding-left: $footer-narrower-md;
        padding-right: $footer-narrower-md;
    }
    @media (min-width: $screen-lg-min) {
        padding-left: $footer-narrower-lg;
        padding-right: $footer-narrower-lg;
    }
}

.footer {
    margin-top: auto;
    scroll-snap-align: end;
    @media (max-width: $screen-sm-max) {
        margin-top: -20px;
    }

    a {
        text-decoration: none;
        transition: color .35s;
    }

    h3 {
        margin-top: 0;
        font-size: 16px;
    }

    hr {
        position: relative;
        left: 50%;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: -50vw;
        width: 100vw;
    }
}

.footer-heading {
    margin-top: 0;
    font-size: 16px;
    display: block;
}

.footer-copyright {
    white-space: nowrap;
    color: rgba(255, 255, 255, .5);
    flex: 1;
}

.footer-developer {
    color: rgba(255, 255, 255, .5);
    flex: 1;

    @media (max-width: $screen-sm-max) {
        margin-top: 11px;
    }

    @media (max-width: $screen-sm-max) {
        flex-basis: 100%;
    }
}

.footer-bottom-row {
    display: flex;
    @include footer-narrow;

    @media (max-width: $screen-sm-max) {
        flex-wrap: wrap;
    }

    @media (max-width: $screen-xs-max) {
        flex-direction: column;
        align-items: center;
    }
}

.footer-nav {
    background-color: $gray-lighter;
    padding-top: 40px;
    padding-bottom: 48px;
    border-radius: 8px;
    @include footer-narrow;

    @media (max-width: $screen-xs-max) {
        padding-top: 20px;
        padding-bottom: 24px;
    }
}

.footer-social-icons {
    text-align: center;
    flex: 1;


    @media (max-width: $screen-sm-max) {
        text-align: right;
    }

    @media (max-width: $screen-xs-max) {
        order: 3;
        text-align: left;
        margin-top: 18px;
    }

    > ul {
        @include list-unstyled;
        margin-bottom: 0;
        font-size: 0;
        vertical-align: middle;
        display: inline-block;

        > li {
            float: left;
            margin-right: 10px;
            margin-left: 10px;
            color: $brand-primary;

            > a {
                display: block;

                &:hover,
                &:focus {
                    color: #FFFFFF;
                }
            }
        }
    }
}

.footer-sub {
    padding-top: 10px;

    > a {
        margin-left: 5px;
    }

    > ul {
        @include list-unstyled;
        @include clearfix;
        margin-bottom: 0;

        > li {
            float: left;
            margin-right: 10px;

            &.dropdown-lang {
                margin-right: 0;

                .flag {
                    margin-right: 5px;
                    width: 18px;
                    height: 18px;
                    border: 1px solid rgba(255, 255, 255, .75);
                    border-radius: 50%;
                    vertical-align: middle;
                    display: inline-block;
                }

                .dropdown-menu {
                    background-color: darken($brand-secondary, 5%);
                    min-width: 0;
                    border-radius: 5px;
                    font-size: $font-size-small;

                    > li {
                        > a {
                            color: #fff;
                        }

                        & > a:hover,
                        & > a:focus,
                        &.active > a {
                            background-color: rgba(0, 0, 0, .25);
                            color: $brand-primary;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: $screen-xs-min) {
        padding-bottom: 10px;
    }

    @media (min-width: $screen-sm-min) {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 120px;
        background-position: 100% 50%;
    }
}

.footer-top {
    color: lighten($gray-base, 40%);
    margin-top: -72px;
    position: relative;

    hr {
        border-top-color: #ddd;
    }

    a {
        color: inherit;

        &:hover,
        &:focus {
            color: $link-color;
        }
    }

}

.footer-bottom {
    background-color: #2F2F2F;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    padding-bottom: 36px;

    @media (max-width: $screen-sm-max) {
        padding-bottom: 40px;
    }

    @media (max-width: $screen-xs-max) {
        padding-bottom: 30px;
    }

    a {
        color: inherit;

        &:hover,
        &:focus {
            color: $brand-primary;
        }
    }

    hr {
        border-top-color: rgba(255, 255, 255, .25);
    }

    .container {
        //position: relative;
    }
    :before {
        margin-top: -64px;
        padding-top: 72px;
        position: relative;
        z-index: -1;

        @media (max-width: $screen-xs-max) {
            margin-top: -96px;
            padding-top: 112px;
        }
    }
}

.footer-logo {
    display: block;
    max-width: 100px;

    img {
        height: 60px;
    }

    @media (max-width: $screen-xs-max) {
        margin: 0 auto 10px !important;
    }

    @media (min-width: $screen-xs-min) {
        position: absolute;
        display: block;
        top: 50%;
        right: $grid-gutter-width/2;
        max-width: 100px;
        transform: translateY(-50%);
    }
}

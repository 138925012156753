// =====================================================================================================================
// Component: Entries
// =====================================================================================================================


%entry-wide {
    margin: 20px -50vw;
    padding: 30px 50vw;
    border: none;
}

.entry {
    position: relative;
    margin-top: 60px;
    @media (max-width: $screen-sm-max) {
        margin-top: 12px;
    }

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        margin-top: 0;
        font-weight: 400;

        > a {
            color: $headings-color;

            &:hover,
            &:focus {
                color: $link-hover-color;
            }
        }
    }

    p {
        margin-bottom: 20px;
    }

    ul ul,
    ol ul {
        list-style-type: disc;
    }

    figure {
        margin-bottom: 20px;
    }

    blockquote {
        border-left: none;
        display: flex;

        > footer {
            color: $brand-primary;
        }
        &::before {
            content: "";
            display: block;
            background-color: #FFEB00;
            width: 8px;
            margin-right: 24px;
        }
    }

    .dropdown {
        margin-bottom: $line-height-computed;
    }

    .panel-group > .panel {
        border: none;
        border-bottom: unset;
        border-radius: 0;
        box-shadow: none;

        & + .panel {
            margin-top: 0;
        }

        .panel-heading {
            padding: 0;
            border-radius: 0;
        }

        .panel-title {
            font-weight: 500;

            > a {
                position: relative;
                padding: 15px 15px 15px 20px;
                display: block;
                background: #EEEEEE;
                margin-bottom: 10px;
                border-radius: 4px;
                font-weight: bold;

                > .fa {
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    margin-left: 0;
                    transition: transform .15s linear;
                }

                &[aria-expanded="true"] {
                    background: #FFEB00;
                    > .fa {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .panel-body {
            padding-right: 20px;
            padding-left: 20px;
            padding-top: 5px;
            border-top: unset;

            > p {
                color: #646464;
            }

            > p:last-child {
                margin-bottom: 0;
            }
        }
    }

    .embed-responsive {
        margin-bottom: 20px;
    }
}

.entry-title {
    padding-right: 60px;
    @include heading(92px, 0, #FFEB00);
    font-size: 80px;
    margin-bottom: 20px;
    @media (max-width: $screen-sm-max) {
        font-size: 46px;
        margin-bottom: 12px;
        &:before {
            width: 54px;
            height: 54px;
        }
    }


    > .icon {
        color: #fff;
    }
}

.entry-title-sm {
    padding-right: 60px;
    @include heading(68px, 0, #FFEB00);
    font-size: 56px;
    margin-bottom: 20px;
    @media (max-width: $screen-sm-max) {
        font-size: 32px;
        margin-bottom: 12px;
        &:before {
            width: 44px;
            height: 44px;
        }
    }


    > .icon {
        color: #fff;
    }
}

.entry-heading {
    padding: 10px;
    padding-bottom: 10px;
    background-color: $brand-secondary;
    border-radius: $border-radius-base;
    color: $brand-primary;
}

.entry-meta {
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 500;
    color: #aaa;

    span:before {
        margin-right: 5px;
        margin-left: 3px;
        content: "•";
    }
}

.entry-author {
    > img {
        margin: 0 auto 20px;
        display: block;
    }

    > figcaption {
        text-align: center;
    }

    @media (min-width: $screen-md-min) {
        font-size: $font-size-large;
        justify-content: center;
        align-items: center;
        display: flex;

        img {
            margin: 0;
        }

        > figcaption {
            padding-left: 30px;
            text-align: left;
        }
    }
}

.entry-focus {
    @extend %entry-wide;
    background-color: $gray-lighter;
}

.entry-focus-alt {
    margin-bottom: $line-height-computed;
    padding: 30px 30px 10px;
    background-color: $gray-lighter;
}

.entry-list-meta {
    @include list-unstyled;
    margin-bottom: 0;

    > li {
        margin-top: 5px;

        > strong {
            font-weight: 500;
            font-size: 16px;
        }
    }
}

.entry-list-check,
.entry-list-decimal {
    @include list-unstyled;
    margin-top: 20px;
    counter-reset: list-counter;

    > li {
        position: relative;
        margin-bottom: 15px;
        padding-left: 34px;
        font-size: 16px;
        line-height: 1.5;
        min-height: 24px;
        counter-increment: list-counter;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
            background-size: 24px 24px;
            content: "";
            line-height: 24px;
            font-weight: 500;
            text-align: center;
            color: $brand-secondary;
        }
    }
}

.entry-list-check > li:before {
    background-image: url("../images/check.svg");
}

.entry-list-decimal > li:before {
    background-image: url("../images/bullet.svg");
    content: counter(list-counter, decimal);
}

.entry-list-icons {
    @include list-unstyled;
    margin-top: 20px;

    > li {
        position: relative;
        padding-left: 95px;
        min-height: 85px;

        > .icon {
            position: absolute;
            top: 3px;
            left: 0;
        }
    }
}

.entry-dl {
    > dt {
        margin-top: $line-height-computed;
        font-weight: 500;
        font-size: $font-size-h4;

        &:first-child {
            margin-top: 0;
        }
    }
}

.entry-icon {
    margin-bottom: 30px;
    font-weight: 500;
    text-align: center;
    display: block;

    > strong,
    > span {
        display: block;
    }

    > strong {
        margin-bottom: 10px;
        font-weight: 400;
        font-size: $font-size-h3;
        line-height: 1.1;
        color: $text-color;
    }

    > span {
        color: $text-color;
        transition: color .35s;
    }

    .text {
        margin-bottom: 10px;
        font-weight: 400;
    }

    > .icon {
        margin-bottom: 20px;
        color: #fff;
        transition: transform .35s, color .35s;
    }

    &:hover {
        > span {
            color: $link-color;
        }

        .text {
            color: $text-color;
        }

        > .icon {
            color: $gray-lighter;
            //transform: translateY(10px);
        }
    }
}

.entry-post {
    position: relative;

    .entry-meta {
        font-weight: 400;
        line-height: 1;

        .small:before {
            display: none;
        }
    }

    @media (max-width: $screen-sm-max) {
        .entry-title {

        }
    }

    @media (min-width: $screen-sm-min) {
        padding-left: 95px;

        .entry-meta {
            position: absolute;
            top: 0;
            left: 0;
            margin-top: 5px;
            padding-right: 30px;
            width: 95px;
            font-weight: 400;
            font-size: $font-size-h1;
            line-height: 1;
            text-align: right;
            text-transform: uppercase;

            .small {
                font-size: 16px;
                display: block;
            }
        }
    }
}

.section-padded {
    .entry p {
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
    }

    .entry h3 h2 {
        font-weight: bold;
        font-size: 26px;
        line-height: 26px;
    }
}

// =====================================================================================================================
// Component: Slideshow
// =====================================================================================================================


.cover-full-width {
    position: relative;

    .slick-slide {
        margin-right: 0;
    }

    .slideshow-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (max-width: $screen-sm-max) {
            margin-left: 0;
        }
        @media (max-width: $screen-xs-max) {
            margin-top: 20px;
        }
        @media (max-width: $screen-xs) {
            flex-direction: column;
            align-items: start;
        }
        @media (min-width: $screen-lg) {
            align-items: start;
        }
    }
}

.cover-full-width {
    width: 100vw;
    .slideshow-item {
        &:focus {
            outline: none;
        }
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.slideshow-nav {
    width: 100px;
    @include clearfix;

    .btn {
        float: left;
        padding: 0;
        background-color: transparent;
        width: 50px;
        height: 50px;
        border: none;
        border-radius: 0;
        opacity: .5;
        transition: opacity .35s;

        &:hover {
            opacity: 1;
        }

        &.slick-disabled {
            opacity: .1;
            cursor: not-allowed;
            pointer-events: none;
        }
    }
}

.slideshow-default {
    background-color: #fff;

    .slideshow-nav {
        background-color: #fff;
    }
}

.slideshow-gray {
    background-color: $gray-lighter;

    .slideshow-nav {
        background-color: $gray-lighter;
    }
}

.slideshow-entry {
    margin-bottom: 20px;
    overflow: hidden;

    .slideshow-nav {
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: #fff;
        z-index: 10;
    }

    .slideshow-caption {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 100px;
        padding: 15px;
        @include gradient-vertical(rgba(0, 0, 0, 0), rgba(0, 0, 0, .15));
        color: #fff;
    }
}

.slideshow-wide {
    .slideshow-body {
        align-items: center;
        flex-direction: column;
    }

    .slideshow-image {
        align-self: flex-start;
    }

    .slideshow-info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: $screen-xs) {
            margin-top: 5vw;
        }
        @media (min-width: $screen-lg) {

        }
    }

    .slideshow-link {
        @include links(12px, 6px, #FFEB00);
        font-size: 26px;
        line-height: 32px;
        min-width: 110px;
        height: fit-content;
        @media (max-width: $screen-xs-max) {
            font-size: 22px;
        }
    }

    .slideshow-caption {
        position: absolute;
        padding: 48px 0; // 54px
        max-width: 524px;
        @media (max-width: 524px) {
            max-width: 100vw;
        }
        //@media (max-width: $screen-sm-max) {
        //    padding: 0;
        //    padding-top: 48px;
        //}

        @media (max-width: $screen-xs-max) {
            padding: 48px 32px;
        }
        @media (max-width: 479px) {
            padding: 36px 24px;
        }
        @media (max-width: 380px) {
            padding: 24px 8px;
        }

        h3 {
            margin-top: 0;
            margin-bottom: 0;
            font-family: $font-family-brand;
            position: relative;
            z-index: 1;
            font-size: 58px;
            font-weight: 400;

            @media (max-width: $screen-xs-max) {
                font-size: 52px;
            }
            @media (max-width: 479px) {
                font-size: 36px;
                line-height: 32px;
            }

            > a {
                color: $text-color;

                &:hover,
                &:focus {
                    color: $link-color;
                }
            }
        }

        p {
            font-size: 18px;
            @media (max-width: $screen-xs-max) {
                font-size: 16px;
                display: none; // fixme:
            }
        }

        p:first-of-type {
            margin-top: 0;
        }

        p:last-child {
            margin-bottom: 0;
        }

        .meta {
            margin-bottom: 5px;
        }
    }

    .slideshow-nav {
        position: absolute;
        top: 0;
        left: 5%;
        margin-top: 200px;
        z-index: 10;
        width: 90%;
        background-color: transparent;
        display: flex;
        pointer-events: none;
        .slick-arrow {
            pointer-events: auto;
        }
    }

    @media (min-width: $screen-sm-min) {
        .slideshow-body {
            align-items: center;
            flex-direction: row;
        }

        .slideshow-image {
            width: 100vw;
            padding-top: 24px;
        }

        .slideshow-nav {
            top: 0;
        }

        &.slideshow-reverse {
            .slideshow-body {
                flex-direction: row-reverse;
            }
        }
    }

    @media (min-width: $screen-lg-min) {
        &.slideshow-reverse .slideshow-caption.slideshow-caption-wide {
            max-width: 740px;
            .slideshow-info {
                max-width: 524px;
            }
            //padding: 36px 15px 0 30px;
        }
    }
}

.slideshow-banner,
.slideshow-comfort,
.slideshow-advantages,
.slideshow-partners,
.slideshow-videos,
.slideshow-featured-products
{
    .slideshow-nav {
        position: absolute;
        top: -70px;
        right: -15px;
        z-index: 10;
        &:after, &:before {
            content: unset;
        }
    }

    .slideshow-item .thumbnail {
        margin-bottom: 30px;
    }

    .slick-dots {
        @include list-unstyled;
        margin-bottom: 30px;
        text-align: center;

        > li {
            font-size: 0;
            display: inline-block;

            > button {
                margin: 0;
                padding: 10px;
                border: none;
                background-color: transparent;
                &:after {
                    background-color: $text-color;
                    border-radius: 50%;
                    width: 10px;
                    height: 10px;
                    opacity: .5;
                    transition: background-color .35s, opacify .35s, transform .25s;
                    display: block;
                    content: " ";
                }
            }

            &.slick-active {
                > button:after {
                    opacity: 1;
                    transform: scale(1.2);
                }
            }
        }
    }

    @media (max-width: $screen-xs-max) {
        .slideshow-nav {
            display: none;
        }
    }
}
.slideshow-featured-products {
    .slick-dots {
        > li {
            > button {
                background-color: #ffffff;
                opacity: 1;

            }

            &.slick-active {
                > button {
                    background-color: $text-color;
                    transform: scale(1.2);
                }
            }
        }
    }
}

.slideshow-featured-products .slideshow-item .thumbnail {
    .thumbnail-image {
        width: 100%;
        height: 160px;
    }
    img {
        //TODO Set for img params
        width: 160px;
        height: 160px;
    }
}

.products-cover h2 {

}
.slick-slide {
    margin-left: 0;
    margin-right: 20px;
}
.slideshow-featured-products .slick-dots {
    margin-top: 40px;
    margin-bottom: 60px;

    & > li {
        margin: 0 4px;

        & > button {
            width: 8px;
            height: 8px;
        }
    }
}

$footer-narrower-xs: 22px;
$footer-narrower-sm: 56px;
$footer-narrower-md: 68px;
$footer-narrower-lg: 72px;
.slideshow-featured-products {

    @media (min-width: $screen-sm-min) {

    }
    @media (min-width: $screen-md-min) {

    }
    @media (min-width: $screen-lg-min) {

    }
}

.slideshow-tutorial {
    .slideshow-caption {
        position: relative;
        padding: 85px 30px 30px;

        > p:last-child {
            margin-bottom: 0;
        }
    }

    .slideshow-pager {
        font-weight: 500;
        font-size: 18px;
        line-height: 60px;
        text-align: center;
        color: $brand-secondary;
        display: block;
        transform: translateX(-50%);
    }

    .slideshow-image + .slideshow-caption {
        padding-top: 45px;

        .slideshow-pager {
            top: -30px;
        }
    }
}
.slideshow-caption.slideshow-caption-narrow {
    p {
        max-width: 320px;
    }
}

.arrow-next {
    @include circleBeforeVisible(24px, #FFEB00, 6px);

    width: 34px;
    height: 34px;
}


.arrow-next-white {
    @include circleBeforeVisible(24px, #FFFFFF, 6px);
    background-color: transparent;
    width: 34px;
    height: 34px;
}

.slideshow-tutorial-dots {
    align-items: center;
    display: flex;
    margin-left: 12px;
    margin-right: 12px;
    .slick-dots {
        @include list-unstyled;
        display: flex;
        margin-bottom: 0;
        button {
            background: unset;
            border: unset;
        }
        .slick-active {
            button {
                color: #79630D;
            }
        }
    }
}

.slideshow-partners {
    & .slideshow-item {
        display: flex;
        margin-right: 0;
    }
}

// =====================================================================================================================
// Component: Lists
// =====================================================================================================================

.list-thumbnails {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
}

.list-services {
    ul {
        @include list-unstyled;
        @include make-row();

        margin-bottom: 30px;

        > li {
            @include make-sm-column(6);
            @include make-md-column(4);
            min-height: 65px;

            > a {
                position: relative;
                padding-left: 95px;
                color: $text-color;
                display: block;

                > strong {
                    font-weight: 500;
                    font-size: $font-size-h4;
                    display: block;
                    transition: color .35s;
                }

                > .icon {
                    position: absolute;
                    left: 0;
                    top: 3px;
                    color: #fff;
                    transition: color .35s, left .35s ease-in-out;
                }

                > .label {
                    align-self: flex-start;
                    margin-bottom: 5px;
                    padding: 2px;
                    border-radius: 0;
                    font-size: 10px;
                    text-transform: uppercase;
                    text-align: center;
                }

                &:hover,
                &:hover {
                    > strong,
                    > strong {
                        color: $link-color;
                    }

                    > .icon {
                        left: 15px;
                        color: #eee;
                    }
                }
            }
        }
    }

    @media (max-width: $screen-xs-max) {
        ul > li:nth-child(n+2) {
            margin-top: $grid-gutter-width;
        }
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        ul > li:nth-child(n+3) {
            margin-top: $grid-gutter-width;
        }
    }

    @media (min-width: $screen-md-min) {
        ul > li:nth-child(n+4) {
            margin-top: $grid-gutter-width;
        }
    }
}

.list-downloads {
    ul {
        @include list-unstyled;
        @include make-row();
        margin-bottom: 0;
        padding-left: 26px;

        > li {
            @include make-sm-column(4);
            padding-left: 26px;

            > .icon {
              margin-left: -26px;
              margin-bottom: -48px;
            }

            &:before {
                position: absolute;
                top: 0;
                right: ($grid-gutter-width / 2);
                left: ($grid-gutter-width / 2);
                content: "";
            }

            > a {
                padding-right: 30px;
                font-weight: 500;
                font-size: 16px;
                line-height: 1.1;
                color: $text-color;
                display: block;

                &:hover,
                &:focus {
                    color: $link-color;
                }

                > .fa {
                    margin-right: 5px;
                }
            }

            > .type {
                font-weight: 400;
                font-size: $font-size-small;
                color: $gray-light;
            }

            .label {
                margin-bottom: 5px;
            }

            .actions {
                margin-top: 10px;
                font-size: 13px;
                color: $gray-light;
                display: block;

                > a {
                    color: $link-color;

                    &:hover,
                    &:focus {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .btn-group-tools {
        right: 15px;
    }
}

.list-downloads-inline {
    > ul > li {
        float: none;
        width: auto;
        padding-top: 0;
        margin-bottom: 0;

        &:before {
            display: none;
        }

        > .btn-group-tools {
            top: -10px;
        }
    }
}

// =====================================================================================================================
// Component: Thumbnails
// =====================================================================================================================


.thumbnail {
    border: none;

    .meta {
        margin-bottom: 6px;
        + .entry-datetime-small {
            margin-bottom: 2px;
        }
    }

    .caption {
        position: relative;
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        margin: 2px;
        width: 20px;
        height: 20px;
        z-index: 1;
    }

    .thumbnail-image {
        position: relative;
        border-radius: $thumbnail-border-radius;
        overflow: hidden;
        display: block;
    }

    .thumbnail-title {
        margin-top: 0;
        margin-bottom: 0;
        font-weight: $headings-font-weight;
        font-size: 18px;
        color: $text-color;
        transition: color .35s;

        > a {
            color: $text-color;
            font-weight: normal;

            &:hover,
            &:focus {
                color: $link-color;
            }
        }
    }

    .btn-compare {
        margin-top: auto;

        &:hover {
            color: $link-color;
        }
    }

    &:hover,
    &:focus {
        text-decoration: none;

        .thumbnail-title {
            color: $link-color;
        }
    }

    .color-cards & {
        background-color: transparent;
    }
}

.thumbnail-inline {
    align-items: stretch;
    flex-direction: column;
    display: flex;

    @at-root {
        a.thumbnail-inline:hover,
        a.thumbnail-inline:focus {
            border-color: $gray-lighter;
        }
    }

    .thumbnail-image {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        .label {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            line-height: 1;
        }

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: rgba(0, 0, 0, .3);
            border-radius: 50%;
            color: #fff;
            z-index: 1;
            transform: translate(-50%, -50%);
            transition: background-color .35s;
        }
    }

    .thumbnail-infos {
        display: flex;
        flex-direction: column;
    }

    .thumbnail-title {
        margin-top: 0;
        margin-bottom: 0;
    }

    .thumbnail-excerpt {
        margin-top: 5px;
        margin-bottom: 0;
        color: $brand-gray-dark;

        .thumbnail-excerpt-value {
            color: $text-color;
        }
    }

    .caption {
        flex: 1;
        flex-direction: column;
        display: flex;
    }

    &:hover {
        .thumbnail-image .icon {
            background-color: rgba(0, 0, 0, .6);
            color: $brand-primary;
        }
    }


    @media (max-width: $screen-xs-max) {
        .thumbnail-title {
            font-size: 16px;
        }

        .thumbnail-excerpt {
            font-size: 12px;
        }

        .caption {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
}

.thumbnail-inline-cover {
    padding: 20px;
    min-height: 296px;
    @media (min-width: $screen-xs-min) {
        max-width: 208px;
    }
    @media (min-width: $screen-sm-min) {
        max-width: 283px;
    }
    &.thumbnail-inline {
        align-items: center;
        .label {
            padding: 7px .6em 4px;
        }
    }
    margin-left: auto;
    margin-right: auto;
}

.thumbnail-sm {
    border: none;

    .caption {
        justify-content: center;

        .thumbnail-infos {
            flex-grow: 0;
        }
    }
}

.thumbnail-default {
    .thumbnail-image {
        padding-top: 56.25%;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: inline-block;
        }
    }
}

.thumbnail-video {
    @extend .thumbnail-default;

    .thumbnail-image {
        img {
            object-fit: cover;
            object-position: center;
        }

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            color: #fff;
            z-index: 1;
            transform: translate(-50%, -50%);
        }

        &:before {
            z-index: 1;
            content: ' ';
            width: 100%;
            height: 100%;
            top: 0;
            display: block;
            position: absolute;
            background-color: rgba(0,0,0,0.3);
            transition: background-color .35s;
            pointer-events: none;
        }
    }

    &:hover {
        .thumbnail-image:before {
            background-color: rgba(0, 0, 0, .6);
        }

        .thumbnail-title > a {
            color: $link-color;
        }
    }
}

.thumbnail-document {
    .caption {
        padding-right: 30px;
        min-height: 80px;
    }

    .thumbnail-excerpt {
        margin-top: auto;
    }

    .thumbnail-title > .type {
        font-weight: 400;
        font-size: 14px;
        display: block;
    }

    .thumbnail-input {
        padding: 10px 10px 10px 20px;
        align-self: center;

        & + .thumbnail-image {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}

.thumbnail-article {
    .thumbnail-title {
        font-weight: normal;
    }

    .label {
        padding: 8px .6em 0;
        line-height: 1;
        border-radius: 24px;
    }
}

.thumbnail-blog {
    .thumbnail-title {
        font-weight: normal;
    }

    .label {
        padding: 8px .6em 0;
        line-height: 1;
        border-radius: 24px;
    }
    margin-bottom: 8px;
    .list-inline {
        margin-bottom: 0;
    }

}

.entry-datetime-small {
    text-transform: lowercase;
    font-size: 14px;
    color: $text-color;
    letter-spacing: 1px;
    margin-bottom: 2px;
}

// =====================================================================================================================
// Theme: Base
// =====================================================================================================================

body {
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

body {
    position: relative;
    text-rendering: optimizeLegibility;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:focus {
    outline: none;
}

a {
    transition: color .35s;

    &:focus {
        outline: none;
    }
}

ul,
ol {
    padding-left: 20px;
}

abbr[title],
abbr[data-original-title] {
    text-decoration: none;
}

def[title] {
    border-bottom: 1px dotted;
    cursor: help;
}

// Image
// ---------------------------------------------------------------------------------------------------------------------
img {
    max-width: 100%;
    height: auto;
}

.dialog-off-canvas-main-canvas {
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}

body[data-scroll-snap=true] {
    .dialog-off-canvas-main-canvas {
        //scroll-snap-type: y proximity;
    }
}

.mfp-iframe-holder .mfp-content {
    max-width: unset;
}

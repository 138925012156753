// =====================================================================================================================
// Component: Product
// =====================================================================================================================


.product {
    position: relative;
}

.product-header {
    margin-bottom: 10px;
    padding-right: 60px;
}

.product-header-sticky {
    background-color: #fff;
    z-index: 2;

    &.is_stuck {
        border-bottom: 1px solid #eee;
    }

    @media (max-width: $screen-xs-max) {
        position: static !important;
    }
}

.product-title {
    margin-top: 0;
    margin-bottom: 0;
    @include heading(68px, 10px, #FFEB00);
    font-size: 56px;
    font-weight: normal;
}

.product-name {
    font-weight: 400;
    font-family: $font-family-name;
}

.product-former-name {
    @extend .product-title;
    color: $gray-light;
    display: inline-block;
}

.product-image {
    position: relative;
    margin-bottom: 10px;
    overflow: hidden;
    -webkit-transform: translateZ(0);

    &:hover > .product-image-icon {
        opacity: 0;
        visibility: hidden;
    }
}

.product-image-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    padding: 8px;
    border-top-left-radius: $border-radius-base;
    text-align: center;
    color: #fff;
    opacity: 1;
    z-index: 1;
    display: none;
    transition: opacity .35s ease-in-out;

    > .icon {
        width: 30px;
        height: 30px;
    }

    @media (min-width: $screen-sm-min) {
        display: block;
    }
}

.product-description {
    font-size: 22px;
    line-height: 1.5;

    > h2 {
        margin-bottom: 5px;
        font-size: 16px;
    }

    > h3 {
        font-weight: normal;
    }
}

.product-packaging {
    @include list-unstyled;

    > li {
        display: inline-block;

        &:nth-child(n+2):before {
            margin-right: 4px;
            content: "•";
        }

        > a.active {
            pointer-events: none;
            color: #000;
        }
    }
}

.product-actions {
    @include list-unstyled;
    display: flex;
    flex-direction: column;
    margin-top: 28px;
    align-items: start;

    > li {
        margin-top: 10px;
        margin-right: 20px;
    }

    @media (min-width: $screen-md-min) {
        > li:first-child {
            margin-top: 0;
        }
    }
    @media (min-width: $screen-sm-min) {
        flex-direction: row;
        > li {
            margin-top: 0;
        }
    }
}

.product-color {
    margin-bottom: 20px;

    > a {
        display: block;
    }

    img {
        border-radius: $border-radius-base;
    }

    > .caption {
        padding: 5px 10px;
        font-weight: 500;
        font-size: $font-size-base;
    }
}

.product-colors {
    @media (max-width: $screen-xs-max) {
        .product-color:nth-last-of-type(2) {
            margin-bottom: 0;
            background-color: red;
        }
    }
}

.product-body {
    margin-top: 28px;

    @media (min-width: $screen-sm-min) {
        margin-top: 60px;
    }
}

.product-instructions {
    color: #646464;
    line-height: 32px;
    font-size: 18px;
    font-family: Calibri, Helvetica, Arial, sans-serif;
}

.product-warning {
    background-color: #eeeeee;
    border-radius: 8px;
    margin-top: 20px;
    padding: 8px 20px 25px;

    @media (min-width: $screen-sm-min) {
        margin-top: 30px;
    }

    p {
        color: #646464;
        max-width: 400px;
        font-size: 18px;
    }
}

.property-row {
    display: flex;
    font-size: 18px;
    margin-bottom: 12px;
}

.property-key {
    flex: 1;
    display: flex;
    color: #646464;
    &::after {
        display: block;
        content: '';
        border-bottom: 1px dashed #979797;
        flex: 1;
        margin-left: 8px;
        margin-right: 8px;
    }
}

.product-color-circle {
    width: 32px;
    height: 32px;
    border-radius: 16px;
}

.product-image {
    img {
        object-fit: contain;
    }
}

// =====================================================================================================================
// Component: Buttons
// =====================================================================================================================
// fixme: move to either BEM or AtomicCSS to avoid namespace collisions

$btn-outline-radius: 40px;

.btn {
    padding: 8px 16px;
    border-radius: $btn-outline-radius;
    transition: .35s;

    &:focus,
    &:focus:active {
        outline: none;
        box-shadow: none;
    }
}

.btn-default {
    @include button-variant($text-color, #ccc, #ccc);
}

.btn-primary {
    @include button-variant($text-color, $brand-primary, $brand-primary);
}

.btn-outline {
    &.btn-default {
        @include button-variant($text-color, transparent, #ccc);

        &:hover,
        &:focus,
        &:active {
            @include button-variant($text-color, #ccc, #ccc);
        }
    }

    &.btn-primary {
        @include button-variant($brand-primary, transparent, $brand-primary);

        &:hover,
        &:focus,
        &:active {
            @include button-variant($text-color, $brand-primary, $brand-primary);
        }
    }

}

.close {
    opacity: .6;

    &:hover,
    &:focus {
        color: $brand-blue;
        opacity: 1;
    }
}

.btn-action-compare-reset {
    margin-left: 10px;
    background: transparent;
    border: none;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.btn-primary-ext {
    @include button($text-color, $brand-primary, $brand-primary);
    svg {
        margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 4px;
    }
}

.btn-outline-ext {
    @include button-transparent($brand-primary);
    svg {
        margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 4px;
    }
}

.btn-light-ext {
    @include button-light;
}

.btn-small {
    min-width: 118px;
}

.results_consumption { // fixme: yea
    th, td {
        border-top: 0 solid transparent !important;
    }
}

// =====================================================================================================================
// Component: Navbar
// =====================================================================================================================

.navbar {
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.05);
    min-height: 58px;
    .container {
        vertical-align: top;
        white-space: nowrap;
    }
    .navbar-collapse > div {
        display: flex;
    }
    .navbar-right {
        margin-left: auto;
    }
}

.navbar-nav {
    display: flex;
    > li {
        align-items: center;
    }
}

.navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-left: 5px;
    max-width: 270px;
    font-size: 0;
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    height: 58px;

    > img {
        width: auto;
        height: 40px;
        display: inline-block;
    }

    @media (max-width: $screen-xs-max) {
        float: none;
        //margin-right: 50px;
        //margin-left: 50px;
        padding-right: 0;
        padding-left: 0;
        justify-content: center;

        > img {
            max-width: 180px;
            display: inline-block;
        }
    }

    @media (min-width: $screen-sm-min) {
        > img {
            height: 42px;
        }
        > img.logo-secondary {
            padding-right: 5px;
            padding-left: 5px;
            height: 32px;
        }
    }

    @media (min-width: $screen-md-min) {
        padding-left: 2px;
        height: 70px;

        > img {
            height: 58px;
        }
        > img.logo-secondary {
            height: 40px;
        }
    }

    @media (min-width: $screen-lg-min) {
        padding-left: 2px;
        height: 80px;
    }
}

.navbar-toggle {
    float: right;
    margin-right: 0;
    margin-left: 5px;
    padding: 15px 11px;
    border-radius: 0;

    .hamburger-box {
        vertical-align: middle;
    }

    @media (min-width: $grid-float-breakpoint) {
        display: none !important;
    }
}

.navbar-phone {
    @extend .navbar-toggle;
    float: right;
    margin: 0;
    padding: 13px;
    border: none;
    color: #fff;

    &:hover,
    &:focus,
    &:active {
        color: $brand-primary;
    }

    > .icon {
        width: 24px;
        height: 24px;
    }
}

.navbar-link-icon {
    min-width: 50px;

    > span {
        margin-left: 5px;
    }

    @media (min-width: $screen-md-min) {
        min-width: 70px;
        text-align: center;

        > .icon {
            width: 25px;
            height: 25px;
        }
    }
}

.dropdown-wide-menu {
    @extend .dropdown-menu;
    position: static;
    float: none;
    margin-top: 0;
    margin-right: -($grid-gutter-width / 2);
    margin-left: -($grid-gutter-width / 2);
    background-color: transparent;
    border: none;
    box-shadow: none;
    display: block;
}

.dropdown-wide-services {
    @include make-row();

    > li {
        @include make-sm-column(6);
        @include make-md-column(4);
        margin-bottom: 5px;

        > a > .icon {
            margin-right: 5px;
        }
    }
}

.navbar-nav {
    margin-top: 0;
    margin-bottom: 0;

    > li {
        > a {
            @include menu-link(#FFEB00);
        }
        &.open > a {
            &:after {
                background-color: #FFFFFF;
            }
        }

        .dropdown-header {
            font-weight: 500;
            font-size: 18px;
            color: $text-color;
        }

        .dropdown-menu,
        .dropdown-wide-menu {
            background-color: $brand-primary;
            border: none;
            box-shadow: none;

            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;

            > li > a {
                color: lighten($gray-base, 40%);
            }

            > li > a:hover,
            > li > a:focus,
            > li.active > a {
                background-color: transparent;
                color: $link-color;
            }

            > li.disabled > a,
            > li.disabled > a:hover,
            > li.disabled > a:focus {
                color: lighten($gray-base, 80%);
            }

            .navbar-form {
                margin-top: 0;
                margin-bottom: 0;

                .form-control:focus {
                    box-shadow: none;
                    border-color: lighten($gray-base, 80%);
                }

                .input-group {
                    .input-group-btn > .btn {
                        padding-top: 9px;
                        padding-bottom: 9px;
                        border-color: darken($brand-primary, 10%);
                        border-radius: 20px;
                    }
                }
            }
        }

        .dropdown-menu {
            box-shadow: 0 6px 18px rgba(0, 0, 0, 0.125);
        }
        .dropdown-wide-menu {
            box-shadow: none;
        }

        .dropdown-wide-menu-full {
            > li {
                float: none;
                width: auto;

                > a {
                    width: 100%;
                    white-space: normal;
                    display: table;

                    > svg,
                    > span {
                        vertical-align: middle;
                        display: table-cell;
                    }
                }
            }
        }

        &.dropdown-search {
            > a > svg {
                pointer-events: none;
            }

            > .dropdown-menu {
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }

        &.open {
            > a {
                > .caret {
                    border-top: 0;
                    border-bottom: 4px dashed;
                }

                > .icon {
                    color: $brand-primary;
                }
            }

            .dropdown-menu > li > a,
            .dropdown-menu .dropdown-header {
                padding-left: 15px;
            }
        }

        &.dropdown-wide.open {
            .dropdown-menu .dropdown-header {
                margin-right: -($grid-gutter-width / 2);
                margin-left: -($grid-gutter-width / 2);
            }

            .dropdown-wide-services > li {
                > a {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    position: relative;
                    min-height: 70px;
                    padding-right: 0;
                    padding-left: 75px;
                    white-space: normal;

                    > * {
                        vertical-align: middle;
                    }

                    > .icon {
                        position: absolute;
                        left: 0;
                        color: #eee;
                        transition: color .35s;
                    }

                    > .label {
                        align-self: flex-start;
                        margin-bottom: 5px;
                        padding: 2px;
                        border-radius: 0;
                        font-size: 10px;
                        text-transform: uppercase;
                        text-align: center;
                    }

                    &:hover > .icon,
                    &:focus > .icon {
                        color: #fff;
                    }
                }

                &.active > a > .icon {
                    color: #fff;
                }

                &.disabled > a {
                    > .icon {
                        opacity: .3;
                    }

                    &:hover > .icon,
                    &:focus > .icon {
                        color: #eee;
                    }
                }
            }
        }
    }

    > li.dropdown-wide {

        .dropdown-menu,
        .dropdown-wide-menu {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    > li.highlight > a {
        background-color: $brand-primary;

        &:hover,
        &:focus {
            background-color: #ccbc01;
        }
    }

    > li > a {
        font-weight: 500;
        font-size: 18px;

        &.navbar-link-icon > .icon {
            color: #fff;
            transition: background-color .35s, color .35s;
        }

        &.navbar-link-icon:hover > .icon,
        &.navbar-link-icon:focus > .icon,
        &.navbar-link-icon.active > .icon {
            color: $brand-primary;
        }

        &.navbar-phone-number {
            font-weight: bold;
        }
    }

    @media (max-width: $screen-xs-max) {
        & + .navbar-nav {
            border-top: 1px solid $gray-lighter;
        }

        > li.dropdown-wide {
            > .dropdown-menu {
                .navbar-form {
                    padding-bottom: 5px;
                    border-top-color: lighten($gray-base, 80%);
                }
            }
        }
    }

    @media (min-width: $screen-sm-min) {
        > li.dropdown-wide {
            position: static;

            > .dropdown-menu {
                left: 50%;
                margin-left: -50vw;
                padding-top: $navbar-padding-horizontal;
                padding-bottom: $padding-base-horizontal;
                width: 100vw;
                max-height: 600px;
                overflow: hidden;
                overflow-y: auto;

                .navbar-form {
                    padding-right: 0;
                    padding-left: 0;

                    .form-group,
                    .input-group {
                        width: 100%;
                    }

                    .input-group {
                        display: flex;
                        .input-group-btn {
                            button.btn-primary {
                                @include button-light;
                            }
                        }

                        > .form-control {
                            border-radius: 20px;
                            margin-right: 16px;
                            border-color: transparent;
                            box-shadow: none;
                            font-size: $font-size-large;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        > li > a {
            font-size: 12px;

            &.navbar-link-icon {
                text-align: center;
            }
        }
    }

    @media (min-width: $screen-md-min) {
        > li > a {
            padding-top: 15px;
            padding-bottom: 15px;
            line-height: 40px;
        }
    }

    @media (min-width: $screen-lg-min) {
        > li > a {
            line-height: 50px;
        }
    }
}

.navbar-default {
    padding-left: 10px;
    border-bottom: unset;

    @media (max-width: $screen-xs-max) {
        .navbar-header {
            position: relative;
            z-index: 1;
            background-color: #fff;
        }

        .navbar-collapse {
            box-shadow: 0 0 10px rgba(0, 0, 0, .6);
        }
    }

    @media (min-width: $screen-md-min) {
        padding-left: 20px;
        box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.05);
    }

    @media (min-width: $screen-lg-min) {
        padding-left: 30px;
    }

    .navbar-nav {
        .dropdown-toggle {
            border-radius: 12px 12px 0 0;
        }
        .open {
            .dropdown-toggle {
                background-color: $brand-primary!important; // fixme: fix nasty background color collision in Firefox
                z-index: 1001;
            }
        }
        @media (max-width: $grid-float-breakpoint-max) {
            .open .dropdown-menu {
                > li > a {
                    color: lighten($gray-base, 40%);

                    &:hover,
                    &:focus {
                        color: $link-color;
                        background-color: transparent;
                    }
                }

                > .active > a {
                    &,
                    &:hover,
                    &:focus {
                        color: $link-color;
                        background-color: $navbar-default-link-active-bg;
                    }
                }

                > .disabled > a {
                    &,
                    &:hover,
                    &:focus {
                        color: lighten($gray-base, 80%);
                        background-color: transparent;
                    }
                }
            }
        }
    }
}

.navbar-fixed-top {
    margin-bottom: 0;
}


.search-wrapper {
    display: flex;
    padding-right: 0;
    padding-left: 8px;
    margin-right: -8px;

    .form-group {
        flex: 1;
    }

    .input-group {
        //display: flex;
        .input-group-btn {
            button.btn-primary {
                @include button-light;
                min-width: 118px;
                span {
                    display: none;
                }
            }
            button:after {
                content: "Найти"; // fixme: working around the hardcoded submit form button params
            }
        }

        .form-item {
            width: unset;
            > .form-search {
                border-radius: 40px;
                border-color: transparent;
                box-shadow: none;
                font-size: $font-size-large;
            }
            margin-right: 16px;
        }
    }
}

body[data-embedded-search=true] {
    .navbar, .navbar-default {
        box-shadow: unset;
    }
}

// =====================================================================================================================
// Plugin: Magnific Popup
// =====================================================================================================================


.mfp-bg {
    background-color: #000;
    opacity: .5;
}

.mfp-iframe-holder .mfp-close {
    top: -44px;
    right: 0;
    padding-right: 0;
}

.mfp-arrow-left:before,
.mfp-arrow-right:before {
    display: none;
}

// Type: Gallery
// ---------------------------------------------------------------------------------------------------------------------
.mfp-gallery {
    .mfp-figure:after {
        top: 0;
        bottom: 0;
        border-radius: $border-radius-base;
    }

    img.mfp-img {
        padding-top: 0;
        padding-bottom: 0;
        border-radius: $border-radius-base;
    }

    .mfp-close {
        top: -44px;
        right: 0;
    }

    .mfp-bottom-bar {
        top: auto;
        bottom: -30px;
        margin-top: 0;
    }
}

// Effect: Zoom
// ---------------------------------------------------------------------------------------------------------------------
.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
    opacity: 0;
}

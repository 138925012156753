.categories-item {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;

    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    letter-spacing: 1px;


    @media (max-width: $screen-sm-max) {
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
        + .categories-item {
            //border-top: 2px solid #EEEEEE; fixme: border-top
        }
    }

    span {
        @include footer-link(#FFEB00);
    }


    &:hover, &.active {
        span {
            &:after {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    > .icon {
        width: 32px;
        height: 24px;
        margin-right: 10px;
        &.inverse {
            display: none;
        }
    }

    @media (min-width: $screen-xs-min) {
        flex-direction: column;
        min-width: 100px;
        max-width: 100px;
        white-space: pre-line;

        + .categories-item {
            border-left: 2px solid #EEEEEE;
        }

        > .icon {
            width: 48px;
            height: 36px;
            margin-right: 0;
            margin-bottom: 16px;
        }
    }

    @media (min-width: $screen-md-min) {
        min-width: 121px;
        max-width: 121px;
        > .icon {
            width: 48px;
            height: 48px;
        }
    }

    @media (min-width: $screen-lg-min) {
        min-width: 131px;
        max-width: 131px;
    }
}

.categories-row {
    display: flex;
    flex-direction: column;
    @media (min-width: $screen-xs-min) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
    @media (min-width: $screen-sm-min) {

    }
}

.nav > li > a {
    position: relative;
    display: block;
    padding: 15px 8px;
    font-size: 18px;
}

.dropdown-menu {
    .categories-item {
        span {
            &:after {
                background-color: #FFFFFF;
            }
        }
        + .categories-item {
            border-left: 2px solid #FFFFFF88;
        }
        .icon {
            display: none;
        }
        .icon.inverse {
            display: block;
        }
    }
}

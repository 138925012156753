//COMMON

.store-locator-cover h2, .blog-page-cover h2 {
    line-height: 56px;
    padding-right: 40%;

}

.store-locator-cover p, .blog-page-cover p {
    line-height: 26px;
}

.store-locator-cover a, .blog-page-cover a {
    min-width: unset;
}

.thumbnail-default .thumbnail-image img {
    border-radius: 8px;
}

.navbar {
    .logo-secondary {
        margin-right: 19px;
    }

    .navbar-nav {
        .dropdown-toggle {
            padding-top: 5px;
            margin-top: 10px;
        }

        .dropdown-toggle.navbar-link-icon {
            padding-top: 15px;
            padding-bottom: 0;
        }
    }

    .dropdown-search {
        border-radius: 0;
        z-index: 1001;

        & > .dropdown-tooltip {

            svg {
                margin-top: -20px;
            }


            &:after {
                margin-top: 8px;
            }
        }
    }

    .navbar-form .input-group > .form-control {
        padding-left: 16px;
    }

    div.dropdown-menu {
        margin-top: -10px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
    }
}

.footer {
    .footer-heading a {
        color: #323232;
        font-size: 14px;
        line-height: 20px;
    }
}

.label-new {
    font-size: 12px;
    line-height: 15px;
}

//MAIN

.navbar-right .dropdown-toggle {
    //TODO Update uikit
}

.store-locator-cover {
    padding-top: 46px;
    padding-bottom: 60px;

    & h2:before {
        left: -20px;
        top: -9px;
    }

    .store-locator-cover-row {
        margin-top: 40px;
    }
}

.articles-cover {

    .articles-cover-description {
        padding-top: 0;
        padding-bottom: 40px;

        & h2 {
            font-size: 80px;
            line-height: 112px;
            margin-bottom: 0;

            &:before {

                left: -16px;
                top: 8px;
            }
        }
    }

    .thumbnail {

        & .thumbnail-title {
            font-weight: normal;
            font-size: 20px;
            line-height: 26px;
        }

        & .caption {
            padding: 10px 0;
        }

    }
}

.products-cover {
    .slideshow-featured-products .slideshow-nav {
        top: 125px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        left: 2.5%;
        width: 95%;
        pointer-events: none;
        button {
            pointer-events: auto;
        }
        @media (max-width: $screen-xs-max) {
            width: 100vw;
            left: unset;
        }
    }

    .slideshow-featured-products {

        @media (max-width: $screen-sm) {
            margin-left: -20px;
        }

        .slick-slide {
            margin-left: 0;
            margin-right: 0px;

            @media (max-width: $screen-xs-max) {
                margin-right: 0;
            }
        }

        & .slick-dots {
            margin-top: 10px;
            margin-bottom: 60px;

            & > li {
                margin: 0 4px;

                & > button {
                    width: 8px;
                    height: 8px;
                }
            }
        }

        .thumbnail {

            & .thumbnail-title {
                font-weight: bold;
                font-size: 20px;
                line-height: 30px;
            }

            & .thumbnail-excerpt {
                font-weight: normal;
                font-size: 18px;
                line-height: 22px;
                color: #000000;

                mix-blend-mode: normal;
                opacity: 0.5;
            }

            & .caption {
                padding: 10px 0;
            }

        }
    }
}

.search-cover {
    padding-bottom: 10px;
    padding-top: 25px;
}


//PRODUCTS

.search-result-heading {
    margin-left: -8px;
}

.open-filters-link span:after {
    bottom: 1px;
}

.search-result {
    .caption {
        & .thumbnail-infos {
            & .thumbnail-title {
                margin-bottom: 4px;
                font-weight: bold;
                font-size: 20px;
                line-height: 18px;
            }

            & .thumbnail-excerpt {
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;

                & + * {
                    line-height: 22px;
                }
            }
        }
    }

    .search-result .row {
        margin-left: 5px;
    }

    .row.offcanvas-row.offcanvas-row-left {
        margin-left: 18px;
    }
}

.form-search-filters {
    margin-left: 10px;
    margin-right: 14px;

    .panel-title {
        display: flex;

        & > a {
            line-height: 26px;
            position: relative;

            & .caret {
                float: unset;
                position: relative;
                right: unset;
                top: unset;
            }
        }
    }
}

//GDE KUPIT

.entry {
    margin-top: 44px;
}

.search-cover-light {
    padding-top: 46px;
}

.search-cover-light .cover-form-group {
    padding-top: 50px;
}

.map__balloon__pin__shop_icon {
    margin-top: -108px;
}


//NOVOSTI-I-AKCII

.entry-title {
    margin-bottom: 50px;
}

#articlesList {

    .thumbnail-article .thumbnail-title {
        font-size: 20px;
        line-height: 26px;
    }

    .thumbnail .meta {
        margin-bottom: 0;
    }
}

#meet-show-more {
    display: inline-block;
    margin-bottom: 30px;
}

//BLOG DETAIL

@media (min-width: 768px) {
    .main-container {
        padding-left: 10px;
    }
    .footer {
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .main-container {
        padding-left: 20px;
    }
    .footer {
        padding-left: 0;
    }
}

@media (min-width: 1280px) {
    .main-container {
        padding-left: 30px;
        padding-right: 0;
    }
    .footer {
        padding-left: 0;
    }
}


//PRODUCT DETAIL

#product {
    .product-description > h3 {
        margin-top: 18px;
    }

    .product-body {
        h4 {
            font-weight: bold;
            font-size: 20px;
            line-height: 26px;
        }

        .product-actions {
            margin-top: 36px;
            font-family: Weber, Helvetica, Arial, sans-serif;

            li:not(:first-child) {
                svg {
                    margin-bottom: 0;
                }
            }
        }

        .product-warning {
            margin-top: 40px;
            padding: 18px 30px 28px 30px;
        }
    }
}

.product-info-tabs {
    #tabs {
        margin-top: 80px;
        margin-bottom: 68px;

        .ui-tabs-panel {
            margin-top: 32px;
            padding: 22px 0;
        }
    }
}

.tutorial {
    margin-top: 70px;
    margin-bottom: 140px;
    min-height: 120px;

    .section-title {
        margin-bottom: 18px;
    }

    @media (min-width: 768px) {
        #tutorial-modal-link {
            padding-left: 48px;
            padding-right: 48px;
        }
    }
    #tutorial-modal-link svg {
        margin-bottom: 0;
    }

    .tutorial-buttons {
        top: 0;
        left: -44px;
    }
}

#modal-tutorial {
    .modal-title {
        font-weight: bold;
        font-size: 26px;
        line-height: 26px;
    }

    .close {
        margin-right: -70px;
        margin-top: -44px;

        span {
            color: black;
            font-size: 35px;
        }
    }

    .slideshow-image {
        img {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .slideshow-caption {
        padding-top: 28px;
    }

    .modal-footer {
        padding-top: 30px;
        padding-bottom: 0;
    }

    .slick-dots {
        button {
            font-size: 20px;
            line-height: 24px;
        }
    }
}

.modal-calculator {

    .modal-content {
        margin-bottom: 36px;
    }

    .close {
        margin-right: -62px;
        margin-top: -36px;

        span {
            color: black;
            font-size: 35px;
        }
    }

    .section-title {
        margin-bottom: 36px;
    }

    .calculate_consumption {
        svg {
            margin-bottom: 0;
        }
    }

    #calc-weight-col, #calc-bags-col {
        label {
            margin-bottom: 0;

            ~ * {
                margin-top: -5px;
            }
        }
    }

    //TODO data-drupal-selector="edit-container-input-user" Добавить col-sm-2 для space'а
}

#modal-store-locator {

    .close {
        margin-right: -62px;
        margin-top: -36px;

        span {
            color: black;
            font-size: 35px;
        }
    }

    .modal-content {
        & > div:first-of-type {
            display: flex;
            flex-direction: row;
            margin-bottom: 26px;

            .section-title {
                margin-bottom: 0;
            }

            .checkbox-inverse {
                margin-top: 8px;
                margin-left: 60px;

                label {
                    margin-bottom: 0;
                }
            }
        }
    }
}


@media (max-width: 1024px) {

    //PRODUCT DETAIL
    .tutorial {

        .tutorial-buttons {
            top: 12px !important;
            left: 0 !important;
        }
    }

    .breadcrumb {
        margin-left: 46px;
    }

    #product {
        padding-left: 46px;
        padding-right: 46px;
    }

    #tabs {
        padding-left: 30px;
        padding-right: 66px;
    }
}

@media (max-width: 991px) {

    //PRODUCTS
    .search-result-filters-reset {
        padding: 6px 5px 0 5px !important;
    }

    //PRODUCT DETAIL
    .tutorial {

        .tutorial-buttons {
            margin-left: -34vw;
        }
    }

}

@media (max-width: 767px) {

    //COMMON

    .footer {
    }

    .navbar {
        .navbar-collapse > div {
            flex-direction: column;
        }

        .navbar .container {
            height: 100%;
        }

        #nav-main {
            background-color: white;

            &:before {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                content: "";
            }
        }

        .navbar-default {
            background-color: transparent;

            & .navbar-nav .open .dropdown-toggle {
                background-color: transparent !important;
                padding: 5px 8px;
                margin-bottom: -6px;
            }

            &.navbar-default .navbar-nav > li > a {
                font-weight: bold;
                font-size: 22px;
                line-height: 27px;
            }
        }


        .navbar-nav {
            margin-left: unset;
            flex-direction: column;

            & + .navbar-nav {
                margin-left: unset;
            }

            & .dropdown-search {
                display: none;
            }

            & .dropdown-menu .categories-item .icon.inverse {
                display: none;
            }

            .categories-item {
                font-weight: normal;
                font-size: 18px;
                line-height: 22px;
                color: #646464;
                text-transform: unset;
            }
        }

        .categories-item {
            color: #646464;
        }

        .menu-cover {
            padding-top: 16px;

            .categories-item > .icon {
                width: 32px;
                height: 24px;
            }
        }

        .navbar-form {
            margin-right: 0;
        }
    }

    //MAIN

    .products-cover h2 {

        .slideshow-featured-products .slideshow-item .thumbnail {
            height: 362px;
            //TODO Для отца обновить ширину
        }

        .slideshow-featured-products .slick-dots {
            margin-top: -8px;
            margin-bottom: 30px;
        }
    }

    .store-locator-cover h2, .blog-page-cover h2 {
        font-size: 32px;
        line-height: 32px;

        &:before {
            top: -8px;
        }
    }

    .articles-cover {
        .articles-cover-description {
            & h2 {
                font-size: 46px;
                line-height: 48px;

                &:before {
                    left: -13px;
                    top: -2px;
                }
            }
        }

        & section {
            padding-top: 366px;
            margin-bottom: -138px;
        }

        .thumbnail .thumbnail-title {
            font-size: 18px;
        }

        .thumbnail-sideimage {
            top: 126px;
        }
    }

    //PRODUCTS

    .search-result-heading {
        margin-left: 8px;
    }

    //PRODUCT DETAIL

    .modal-dialog {
        width: 100%;
        height: 100%;

        .modal-content {
            margin: auto 25px auto 15px;
        }
    }

    .modal-calculator {

        .close {
            margin-right: 0;
            margin-top: -20px;
        }

        .section-title {
            margin-bottom: 22px;
            font-size: 24px;
        }

        .calculate_consumption {
            margin-top: 20px;
        }

        .table-result {
            margin-bottom: 15px;
        }
    }

    #modal-tutorial {

        .close {
            margin-right: -26px;
            margin-top: -44px;
        }

        .lead .modal-description {
            display: none;
        }

        .modal-title {
            margin-bottom: 0;
        }

        .slideshow-caption {
            padding: 10px 0 0 0;

            .h4 {
                display: none;
            }
        }
    }
    #modal-store-locator {
        .close {
            margin-right: -10px;
            margin-top: -30px;
        }

        .section-title {
            margin-bottom: 22px;
            font-size: 24px;
        }

        .modal-content {

            div:first-of-type {
                flex-direction: column;

                .section-title {
                    margin-bottom: 16px;
                }

                .checkbox-inverse {
                    margin-top: 0;
                    margin-left: 0;
                }
            }
        }
    }

    .tutorial {
        margin-left: 97px;
        margin-right: 97px;

        .tutorial-buttons {
            margin-left: -28vw;
        }
    }

    #product {
        padding-left: 21px;
        padding-right: 21px;
    }
    .breadcrumb {
        margin-left: 21px;
    }

    #tabs {
        padding-left: 0;
        padding-right: 0;
    }

    //GDE KYPIT

    #store-map {
        min-width: unset;
        width: unset;
        left: 0;
    }

    //BLOG
    //TODO Добавить для контейнера падинги справа слева


    //NOVOSTI-I-AKCII
    #meet-show-more {

        margin-bottom: 26px;
    }
}

@media (max-width: 480px) {

    //MAIN

    .slideshow-featured-products .slideshow-item .thumbnail {
        height: 303px;
        //TODO Для отца -     width: 276px; => 313
    }

    //PRODUCT DETAIL
    .tutorial {
        margin-left: 16px;
        margin-right: 16px;

        .tutorial-buttons {
            margin-left: -6vw;

            #tutorial-modal-link {
                width: 88%;
            }
        }
    }

    #product {
        padding-left: 15px;
        padding-right: 15px;
    }
    .breadcrumb {
        margin-left: 15px;
    }
}

//TODO #modal-calculator нужно в стилях поменять на padding-left: 10px;
.slideshow-featured-products .slick-dots>li.slick-active>button {
    background-color: transparent;
}

.ui-state-active .ui-tabs-anchor {
   color: black!important;
}

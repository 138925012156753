.ui-widget-header {
    border: unset;
    background: unset;
}

.ui-widget.ui-widget-content {
    border: unset;
}

.ui-tabs-nav {
    margin-bottom: 36px;
    display: flex;
    flex-direction: column;
}


@media (min-width: $screen-sm-min) {
    .ui-tabs-nav {
        display: block;
    }
}

.ui-tabs .ui-tabs-nav {
    li {
        border: unset;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1px;
        font-size: 12px;

        background: #EEEEEE;
        border-radius: 4px;
        margin-top: 4px;
        .caret {
            float: right;
        }
        &.ui-state-active {
            background-color: #FFEB00;
            order: 10;
            .caret {
                border-top: 0;
                border-bottom: 4px dashed;
            }
            &:after {
                visibility: visible;
                opacity: 1;
            }
        }
        @media (min-width: $screen-sm-min) {
            background: unset;
            &.ui-state-active {
                background-color: unset;
            }
            @include menu-link(#FFEB00);
            margin-right: 60px;
            .caret {
                display: none;
            }
        }
        // fixme: collision with menu-link
        @media (min-width: $container-desktop) {
            &:after {
                margin-top: 14px;
            }
        }
    }
    .ui-tabs-anchor {
        padding: 1.2em 1em .7em;
        width: 100%;
        @media (min-width: $screen-sm-min) {
            padding: 12px 0;
        }
    }
}

.ui-state-active a, .ui-state-active a:link, .ui-state-active a:visited {
    color: inherit;
}


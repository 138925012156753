// =====================================================================================================================
// Theme: Fonts
// =====================================================================================================================

/* =Fonts Weber
 * =====================================================================================================================
 * This is the font used by web Weber Saint-Gobain.
 * Copyright: Weber Saint-Gobain 2014
 *
 * Font `Weber`
 * ---------------------------------------------------------------------------------------------------------------------
 * 1.  Weber Light          300
 * 2.  Weber Light Italic   300 italic
 * 3.  Weber Regular        400
 * 4.  Weber Regular Italic 400 italic
 * 5.  Weber Medium         500
 * 6.  Weber Medium Italic  500 italic
 * 7.  Weber Bold           700
 * 8.  Weber Bold Italic    700 italic
 *
 * Font `Weber Brush`
 * ---------------------------------------------------------------------------------------------------------------------
 * 9.  Weber Brush          400
 *
 * Font `Weber Name`
 * ---------------------------------------------------------------------------------------------------------------------
 * 10. Weber Name           400
 *
 * ------------------------------------------------------------------------------------------------------------------ */

/* [1] */
@font-face {
    font-family: 'Weber';
    src: url('#{$font-path}/weber/weber-light-webfont.woff2') format('woff2'),
         url('#{$font-path}/weber/weber-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
}

/* [2] */
@font-face {
    font-family: 'Weber';
    src: url('#{$font-path}/weber/weber-lightitalic-webfont.woff2') format('woff2'),
         url('#{$font-path}/weber/weber-lightitalic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: fallback;
}

/* [3] */
@font-face {
    font-family: 'Weber';
    src: url('#{$font-path}/weber/weber-regular-webfont.woff2') format('woff2'),
         url('#{$font-path}/weber/weber-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

/* [4] */
@font-face {
    font-family: 'Weber';
    src: url('#{$font-path}/weber/weber-regularitalic-webfont.woff2') format('woff2'),
         url('#{$font-path}/weber/weber-regularitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: fallback;
}

/* [5] */
@font-face {
    font-family: 'Weber';
    src: url('#{$font-path}/weber/weber-medium-webfont.woff2') format('woff2'),
         url('#{$font-path}/weber/weber-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
}

/* [6] */
@font-face {
    font-family: 'Weber';
    src: url('#{$font-path}/weber/weber-mediumitalic-webfont.woff2') format('woff2'),
         url('#{$font-path}/weber/weber-mediumitalic-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: fallback;
}

/* [7] */
@font-face {
    font-family: 'Weber';
    src: url('#{$font-path}/weber/weber-bold-webfont.woff2') format('woff2'),
         url('#{$font-path}/weber/weber-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
}

/* [8] */
@font-face {
    font-family: 'Weber';
    src: url('#{$font-path}/weber/weber-bolditalic-webfont.woff2') format('woff2'),
         url('#{$font-path}/weber/weber-bolditalic-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: fallback;
}

/* [9] */
@font-face {
    font-family: 'Weber Brush';
    src: url('#{$font-path}/weber-brush/weber-brush-webfont.woff2') format('woff2'),
         url('#{$font-path}/weber-brush/weber-brush-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

/* [10] */
@font-face {
    font-family: 'Weber Name';
    src: url('#{$font-path}/weber-name/weber-name-webfont.woff2') format('woff2'),
         url('#{$font-path}/weber-name/weber-name-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

/* =Fonts Weber Thailand
 * ---------------------------------------------------------------------------------------------------------------------
 * 11. WeberTH Light          300
 * 12. WeberTH Light Italic   300 italic
 * 13. WeberTH Regular        400
 * 14. WeberTH Regular Italic 400 italic
 * 15. WeberTH Medium         500
 * 16. WeberTH Medium Italic  500 italic
 * 17. WeberTH Bold           700
 * 18. WeberTH Bold Italic    700 italic
 *
 * Font `WeberTH Brush`
 * ---------------------------------------------------------------------------------------------------------------------
 * 19. WeberTH Brush          400
 *
 * Font `WeberTH Name`
 * ---------------------------------------------------------------------------------------------------------------------
 * 20. WeberTH Name           400
 *
 * ------------------------------------------------------------------------------------------------------------------ */

/* [11] */
@font-face {
    font-family: 'WeberTH';
    src: url('#{$font-path}/weber/th-weber-light-webfont.woff2') format('woff2'),
         url('#{$font-path}/weber/th-weber-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
}

/* [12] */
@font-face {
    font-family: 'WeberTH';
    src: url('#{$font-path}/weber/th-weber-lightitalic-webfont.woff2') format('woff2'),
         url('#{$font-path}/weber/th-weber-lightitalic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: fallback;
}

/* [13] */
@font-face {
    font-family: 'WeberTH';
    src: url('#{$font-path}/weber/th-weber-regular-webfont.woff2') format('woff2'),
         url('#{$font-path}/weber/th-weber-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

/* [14] */
@font-face {
    font-family: 'WeberTH';
    src: url('#{$font-path}/weber/th-weber-regularitalic-webfont.woff2') format('woff2'),
         url('#{$font-path}/weber/th-weber-regularitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: fallback;
}

/* [15] */
@font-face {
    font-family: 'WeberTH';
    src: url('#{$font-path}/weber/th-weber-medium-webfont.woff2') format('woff2'),
         url('#{$font-path}/weber/th-weber-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
}

/* [16] */
@font-face {
    font-family: 'WeberTH';
    src: url('#{$font-path}/weber/th-weber-mediumitalic-webfont.woff2') format('woff2'),
         url('#{$font-path}/weber/th-weber-mediumitalic-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: fallback;
}

/* [17] */
@font-face {
    font-family: 'WeberTH';
    src: url('#{$font-path}/weber/th-weber-bold-webfont.woff2') format('woff2'),
         url('#{$font-path}/weber/th-weber-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
}

/* [18] */
@font-face {
    font-family: 'WeberTH';
    src: url('#{$font-path}/weber/th-weber-bolditalic-webfont.woff2') format('woff2'),
         url('#{$font-path}/weber/th-weber-bolditalic-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: fallback;
}

/* [19] */
@font-face {
    font-family: 'WeberTH Brush';
    src: url('#{$font-path}/weber-brush/th-weber-brush-webfont.woff2') format('woff2'),
         url('#{$font-path}/weber-brush/th-weber-brush-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

/* [20] */
@font-face {
    font-family: 'WeberTH Name';
    src: url('#{$font-path}/weber-name/th-weber-name-webfont.woff2') format('woff2'),
         url('#{$font-path}/weber-name/th-weber-name-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

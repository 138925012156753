@mixin menu-link($color) {
  @include hoverOrActiveAfterVisible;

  &:after {
    margin: 8px;
    height: 10px;
    background: $color;
    border-radius: 4px;
    z-index: -1;
  }
    @media (min-width: $container-desktop) {
        &:after {
            margin-top: 20px;
        }
    }
}

@mixin footer-link($color) {
  @include hoverOrActiveAfterVisible;

  &:after {
    bottom: 1px;
    height: 8px;
    background: $color;
    border-radius: 4px;
    z-index: -1;
  }
}

@mixin hoverOrActiveAfterVisible {
  position: relative;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;

    visibility: hidden;
    opacity: 0;
    transition: visibility 300ms, opacity 300ms;
  }

  &:hover, &.active {
    &:after {
      visibility: visible;
      opacity: 1;
    }
  }
}

@mixin links($height, $radius, $color) {
  @include hoverOrActiveAfterChangeHeight($height);

  &:after {
    bottom: 4px;
    background: $color;
    border-radius: $radius;
  }
}

@mixin hoverOrActiveAfterChangeHeight($height) {
  position: relative;
  z-index: 1;

  &:after {
    z-index: -1;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;

    transition: height 300ms;
  }

  &:hover, &.active {
    &:after {
      height: $height;
    }
  }
}

@mixin hoverOrActiveBeforeVisible {
    position: sticky;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;

        visibility: hidden;
        opacity: 0;
        transition: visibility 300ms, opacity 300ms;
    }

    &:hover, &.active {
        &:before {
            visibility: visible;
            opacity: 1;
        }
    }
}

@mixin circleBeforeVisible($diameter, $color, $leftPadding) {
    @include hoverOrActiveBeforeVisible;

    &:before {
        top: 50%;
        left: 50%;
        right: unset;
        margin-left: -($diameter / 2) + $leftPadding;
        margin-top: - $diameter / 2;
        width: $diameter;
        height: $diameter;
        border-radius: 50%;
        background: $color;
        mix-blend-mode: multiply;
    }
}

@mixin heading($diameter, $hoverHeight, $color) {
  font-family: $font-family-brand;
  font-weight: normal;
  position: relative;
  padding-left:7px;
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    left: 0px;
    //top: $hoverHeight; fixme: remove the usage of hoverHeight
    background: $color;

    width: $diameter;
    height: $diameter;
    border-radius: 50%;

    transition: height 300ms, width 300ms, bottom 300ms, border-radius 300ms;
      z-index: -1;
  }

  //&:hover, &.active {
  //  &:after {
  //    width: calc(100% + 32px);
  //    height: $hoverHeight;
  //    bottom: 42px;
  //    border-radius: 10px;
  //  }
  //}
}

@mixin defaultButton {
  border-radius: 20px;
  border-style: none;
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  min-width: 207px;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin button($color, $background, $border) {
  @include defaultButton;
  color: $color;
  background: $background;
  border-width: 2px;
  border-style: solid;
  border-color: $border;
  transition: border-color 300ms;

  &:hover, &.active {
    border-color: #323232;
  }
}

@mixin button-light {
  @include defaultButton;

  transition: color 300ms;
  color: white;

  background: #333333;
  font-family: Weber;
  &:hover, &.active {
    color: #FFEB00;;
  }
}

@mixin button-transparent($color) {
  @include defaultButton;
  background-color: transparent;

  border: 2px solid #323232;
  transition: background-color 300ms;

  &:hover, &.active {
    background-color: $color;
  }
}

@mixin circleAfterVisible($diameter, $color, $leftPadding) {
  @include hoverOrActiveAfterVisible;

  &:after {
    top: 50%;
    left: 50%;
    right: unset;
    margin-left: -($diameter / 2) + $leftPadding;
    margin-top: - $diameter / 2;
    width: $diameter;
    height: $diameter;
    border-radius: 50%;
    background: $color;
    mix-blend-mode: multiply;
  }
}

@mixin tag {
  @include hoverOrActiveAfterVisible;

  cursor: pointer;
  border: 2px solid #EEEEEE;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 24px 3px 8px;

  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1px;

  &:before {
    content: "";
    position: absolute;
    bottom: 50%;
    margin-bottom: -5px;
    width: 10px;
    height: 10px;
    right: 8px;
    background-image: url("../images/ic_bubble_exit.png");
  }

  &:after {
    left: unset;
    right: 8px;
    bottom: 50%;
    margin-bottom: -4px;
    height: 8px;
    width: 8px;
    background: #FFEB00;
    border-radius: 50%;
    mix-blend-mode: multiply;
  }
}

@mixin article-tag-base {
    line-height: 1;
    border-radius: 24px;
    color: #646464;
    background-color: #EEEEEE;
    font-size: 12px;
    height: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: unset;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 3px 8px 3px;
}


@mixin checkbox($color, $borderColor, $inactiveColor, $activeColor) {
    position: relative;
    display: inline-block;

    &:hover > input + label {
        color: $activeColor;
    }

    & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked + label::before {
            border-color: $color;
            background-color: $color;
        }

        &:checked + label {
            color: $activeColor;
        }

        &:disabled + label {
            color: #C8C8C8;
            cursor: initial;
        }

        &:disabled + label::before {
            border-color: #C8C8C8;
        }

        &:checked:disabled + label::before {
            border-color: transparent;
            background-color: #C8C8C8;
        }
    }

    & label {
        display: flex;
        width: 100%;
        cursor: pointer;
        color: $inactiveColor;
        transition: color 300ms;

        &::before {
            content: "";
            display: inline-block;
            box-sizing: border-box;
            margin: 3px 11px 1px 1px;
            border-width: 2px;
            border-style: solid;
            border-color: $borderColor;
            min-width: 18px;
            width: 18px;
            max-width: 18px;
            height: 18px;
            vertical-align: top;
            transition: border-color 300ms, background-color 300ms;
        }
    }


    & input[type="checkbox"] + label::before {
        border-radius: 2px;
    }
    & input[type="radio"] + label::before {
        border-radius: 50%;
    }
}

@mixin list {
    list-style-type: none;

    & > li {
        position: relative;
        font-size: 18px;
        line-height: 26px;

        &:before {
            top: 8px;
            width: 10px;
            height: 12px;
            background-image: url("data:image/svg+xml;utf-8,%3Csvg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.39757 11.826C5.29986 11.9329 5.15781 12 5 12C4.845 12 4.70561 11.9353 4.60789 11.8321L1.2564 8.14423C0.474682 7.2799 0 6.14296 0 4.89776C0 2.19285 2.2387 0 5 0C7.76146 0 10 2.19285 10 4.89776C10 6.13761 9.52953 7.27012 8.7539 8.13277L5.39757 11.826Z' fill='%23FFEB00'/%3E%3C/svg%3E%0A");
            content: "";
            position: absolute;
            margin-left: -20px;
        }
    }
}

@mixin input {
    position: relative;
    font-size: 18px;
    line-height: 22px;
    height: 40px;
    display: flex;
    margin-bottom: 15px;

    & input {
        background: #FFFFFF;
        border-style: solid;
        border-color: #C8C8C8;
        border-radius: 4px;
        transition: border-color 300ms;

        flex: 1;
        padding-left: 14px;
        padding-right: 14px;
        padding-top: 14px;

        &:focus, &:not(:focus):valid {
            & ~ span:nth-of-type(1) {
                font-size: 12px;
                line-height: 15px;
                top: 2px;

            }
        }

        &:invalid, &:-moz-submit-invalid, &:-moz-ui-invalid {
            & ~ span:nth-of-type(2) {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .error {
        border-color: #D0006F;


        & ~ span:nth-of-type(2) {
            visibility: visible;
            opacity: 1;
        }
    }

    & span:nth-of-type(1) {
        position: absolute;
        top: 12px;
        left: 14px;
        color: #969696;

        transition: font-size 300ms, line-height 300ms, top 300ms;
    }

    & span:nth-of-type(2) {
        font-size: 12px;
        line-height: 15px;
        color: #D0006F;

        position: absolute;
        bottom: 0;
        margin-bottom: -19px;

        visibility: hidden;
        opacity: 0;
        transition: visibility 300ms, opacity 300ms;
    }
}


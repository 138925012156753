// =====================================================================================================================
// Component: Sections
// =====================================================================================================================


.section {
    scroll-snap-align: center;
}

.section-title {
    margin-top: 0;
    margin-bottom: 26px;
    font-weight: bold;
}

h2.section-title {
    font-size: 26px;
}

.section-icon {
    margin-bottom: 15px;
    color: $brand-secondary;
}

.section-list {
    ul {
        @include list;
        > li {
            margin-top: 8px;
        }
    }
}

// Modifiers
// ---------------------------------------------------------------------------------------------------------------------
.section-wide {
    @extend %wide;
}

.section-wide-sm {
    @media (max-width: $screen-sm-max) {
        position: absolute;
        top: 0;
        z-index: $zindex-modal;
        left: 50%;
        margin-left: -50vw;
        width: 100vw;
    }
}

.section-slim {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.section-padded:last-of-type {
    margin-bottom: 96px;
}

.section-sep {
    //border-top: 1px solid $gray-lighter;
}

.section-cover {
    margin-top: 30px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        background-color: $gray-darker;
        width: 100%;
        height: 100%;
        content: "";
        opacity: .15;
        z-index: 0;
    }

    &:first-of-type {
        margin-top: 0;
    }

    @media (max-width: $screen-xs-max) {
        .jumbotron-title {
            bottom: 100px;
        }
    }

    @media (min-width: $screen-sm-min) {
        .jumbotron-title {
            top: 30%;
        }

        .jumbotron-buttons,
        .jumbotron-nav {
            top: auto;
            bottom: 30%;
            transform: translateY(50%);
        }
    }
}

.section-split {
    @media (min-width: $screen-sm-min) {
        .jumbotron-title,
        .jumbotron-nav,
        .jumbotron-buttons {
            right: calc(50% + 15px);
        }
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
        .jumbotron-title {
            font-size: $font-size-h3 !important;
        }
    }
}

.section-fixed {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $zindex-navbar-fixed;
}

.section-404 {
    height: 100%;
    min-height: calc(100vh - 50px);

    .jumbotron {
        color: $text-color;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
            @include heading(92px, 10px, #FFEB00);
            font-size: 80px;
            @media (max-width: $screen-sm-max) {
                font-size: 46px;
                &:before {
                    width: 54px;
                    height: 54px;
                }
            }
            top: unset!important;
            margin-bottom: 32px;
        }

        p {
            margin-bottom: $line-height-computed;
            font-size: 16px;
        }

        ul > li > a {

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        .lead {
            font-weight: 500;
            font-size: 20px;
            max-width: 360px;
            margin-bottom: 12px;
        }
    }

    .jumbotron-title {
        margin-bottom: $line-height-computed;
    }

    .jumbotron-buttons {
        position: static;
        transform: none;
    }

    @media (min-width: $screen-sm-min) {
        min-height: calc(100vh - 415px);
    }
}

.section-secondary {
    background-color: $brand-secondary;
    color: #fff;

    .section-title {
        color: $brand-primary;
    }

    .thumbnail-inline {
        border: none;
    }

    .slideshow-nav > .btn {
        color: #fff;
    }

    .table-striped > tbody > tr:nth-of-type(odd) {
        background-color: $brand-secondary;
    }
}

.section-gray {
    background-color: $gray-lighter;
}

.section-docs {
    background-color: $brand-primary;
    padding: 40px 5px;
    @media (min-width: $screen-md-min) {
        padding: 80px 0;
    }
    .icon {
        filter: grayscale(1) contrast(1.5);
    }
    .section-title {
        @include heading(68px, 8px, #FFFFFF);
        font-size: 56px;
        @media (max-width: $screen-sm-max) {
            margin-left: 12px;
            font-size: 32px;
            &:before {
                width: 44px;
                height: 44px;
            }
        }
    }
    .show-more-link {
        margin-top: 20px;
        @media (min-width: $screen-md-min) {
            margin-top: 40px;
        }
        &:after {
            background: #FFFFFF;
        }
    }
}

.section-compare {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: $brand-gray-dark;
    color: #fff;

    .section-title {
        margin-bottom: 0;
        font-size: 20px;
        line-height: 24px;
        text-align: left;
        color: $brand-primary;

        > a {
            color: inherit;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &[aria-expanded="false"] > .caret {
                border-top: 0;
                border-bottom: 4px dashed;
            }
        }
    }

    p:first-child {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 16px;
    }
}

.section-product-availability {
    font-size: 16px;


    h4 {
        margin: 30px 0;
    }

    .font-brush {
        margin-bottom: 40px;
    }

    .form-search-nav {
        label {
            margin-left: 20px;
            margin-bottom: 10px;
        }
    }
}

// =====================================================================================================================
// Component: Modal
// =====================================================================================================================


.modal-content {
    min-width: 75vw;
    box-shadow: unset;
    border: unset;
    width: unset;
}

.modal-header {
    position: relative;
    @include border-top-radius($border-radius-base);
    border-bottom: unset;

    > .close {
        position: absolute;
        top: 50%;
        right: $modal-inner-padding;
        transform: translateY(-50%);
    }
}

.modal-title {
    font-weight: 400;
    font-size: $font-size-h3;
}

.modal-video {
    .modal-body {
        padding: 0;
    }

    .embed-responsive {
        border-radius: 8px;
    }
}

.modal-tutorial {
    .modal-title {
        margin-bottom: 10px;
        color: $text-color;
    }

    .lead {
        font-weight: 400;
        font-size: $font-size-base;
        color: $text-color;
    }

    .dropdown {
        margin-bottom: 20px;

        .caret {
            margin-left: 10px;
        }
    }

    .modal-header .close {
        top: 30px;
        font-size: 30px;
    }

    .modal-body {
        padding: 0;
        color: $text-color;
    }
}

.modal-tutorial, .modal-calculator, .modal-store-locator {
    .modal-dialog {
        display: flex;
        @media (min-width: 768px) {
            height: 100%;
            align-items: center;
            justify-content: center;
        }
        pointer-events: none;
    }
    .modal-content {
        background-color: $brand-primary;
        padding: 28px 20px;
        @media (min-width: 768px) {
            padding: 40px 76px;
        }
        pointer-events: auto;
    }
    .form-control {
        box-shadow: unset;
        border: unset;
    }
}
@media (min-width: 768px) {
    .modal-dialog {
        width: 100%;
        //min-width: 55vw;
        //margin: 30px 17.5vw;
    }
}

.modal-store-locator {
    color: $text-color-yellow-bg;
    h1, h2, h3 {
     color: $text-color;
    }
    .store-info-card {
        height: 148px;
        margin-bottom: 8px;
        h3 {
            margin-top: 0;
            margin-bottom: 6px;
        }
        p {
            margin-bottom: 6px;
        }
    }
}

.store-locator-link {
    @include links(12px, 6px, #FFFFFF)
}

.checkbox-inverse {
    @include checkbox(#FFFFFF, #FFFFFF, #646464, #323232);
    color: $text-color-yellow-bg;
}

.modal-calculator {
    label {
        font-size: 20px;
        color: #323232;
    }
    #weber-product-comment {
        font-style: italic;
        opacity: 0.5;
    }
    .calculate_consumption {
        margin-top: 35px;
    }
}

.modal-footer {
    position: relative;
    border-top: unset;
    padding: 0;
    @media (min-width: 768px) {
        padding: 15px;
    }
}

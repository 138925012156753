// =====================================================================================================================
// Component: Labels
// =====================================================================================================================


.label {
    padding: 4px .6em 2px;
    font-size: 90%;
    line-height: $line-height-base;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 24px;
    letter-spacing: .1em;
    font-weight: bold;
}

.label-block {
    display: block;
}

.label-primary {
    border-radius: 0;
    color: $text-color;
}

.label-secondary {
    background-color: $brand-secondary;
    color: $brand-primary;
}

.label-new {
    background-color: $brand-green;
    color: #FFFFFF;
}

.label-popular {
    background-color: $brand-orange;
    color: #FFFFFF;
}

.label-neutral {
    background-color: #EEEEEE;
    color: #646464;
}
